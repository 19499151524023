// webfonts declarations
@font-face {
    font-family: 'poblabook';
    src: url('../fonts/Pobla/pobla-book_trk-webfont.eot');
    src: url('../fonts/Pobla/pobla-book_trk-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Pobla/pobla-book_trk-webfont.woff2') format('woff2'),
         url('../fonts/Pobla/pobla-book_trk-webfont.woff') format('woff'),
         url('../fonts/Pobla/pobla-book_trk-webfont.ttf') format('truetype'),
         url('../fonts/Pobla/pobla-book_trk-webfont.svg#poblabook') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'poblabook_italic';
    src: url('../fonts/Pobla/pobla-book-italic-webfont.eot');
    src: url('../fonts/Pobla/pobla-book-italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Pobla/pobla-book-italic-webfont.woff2') format('woff2'),
         url('../fonts/Pobla/pobla-book-italic-webfont.woff') format('woff'),
         url('../fonts/Pobla/pobla-book-italic-webfont.ttf') format('truetype'),
         url('../fonts/Pobla/pobla-book-italic-webfont.svg#poblabook_italic') format('svg');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'poblabold';
    src: url('../fonts/Pobla/pobla-bold-webfont.eot');
    src: url('../fonts/Pobla/pobla-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Pobla/pobla-bold-webfont.woff2') format('woff2'),
         url('../fonts/Pobla/pobla-bold-webfont.woff') format('woff'),
         url('../fonts/Pobla/pobla-bold-webfont.ttf') format('truetype'),
         url('../fonts/Pobla/pobla-bold-webfont.svg#poblabold') format('svg');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'poblabold_italic';
    src: url('../fonts/Pobla/pobla-bold-italic-webfont.eot');
    src: url('../fonts/Pobla/pobla-bold-italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Pobla/pobla-bold-italic-webfont.woff2') format('woff2'),
         url('../fonts/Pobla/pobla-bold-italic-webfont.woff') format('woff'),
         url('../fonts/Pobla/pobla-bold-italic-webfont.ttf') format('truetype'),
         url('../fonts/Pobla/pobla-bold-italic-webfont.svg#poblabold_italic') format('svg');
    font-weight: 700;
    font-style: italic;
}

// mixins Titillium
@mixin thin {
  font-family: $font-family-sans-serif;
  font-weight: 300;
  font-style: normal;
}

@mixin regular {
  font-family: $font-family-sans-serif;
  font-weight: 400;
  font-style: normal;
}

@mixin regular-italic {
  font-family: $font-family-sans-serif;
  font-weight: 400;
  font-style: italic;
}

@mixin semibold {
  font-family: $font-family-sans-serif;
  font-weight: 600;
  font-style: normal;
}

@mixin semibold-italic {
  font-family: $font-family-sans-serif;
  font-weight: 600;
  font-style: italic;
}

@mixin bold {
  font-family: $font-family-sans-serif;
  font-weight: 700;
  font-style: normal;
}


// mixins Pobla
@mixin poblabook {
  font-family: poblabook, $font-family-serif;
  font-weight: 300;
  font-style: normal;
}

@mixin poblabook-italic {
  font-family: poblabook_italic, $font-family-serif;
  font-weight: 300;
  font-style: italic;
}

@mixin poblabold {
  font-family: poblabold, $font-family-serif;
  font-weight: 700;
  font-style: normal;
}

@mixin poblabold-italic {
  font-family: poblabold_italic, $font-family-serif;
  font-weight: 700;
  font-style: italic;
}

.pagination-centered {
      text-align: center;
      @include grid-row();
      padding: 1.5rem 0 3rem;
  }
ul.pagination {
  padding-top: 1rem;
  border-top: 1px solid $gainsboro;
  margin:0 .9375rem;
  li {
    display: none;
    padding-left: 0;

    &:before{
      content: none;  
    }
    &.hide {
      display: none;
    }

    &:last-child {
      a {
        border-right: 0;
      }
    }

    a {
      border-radius: 0;
      transition: none;
      color: $body-font-color;
      text-decoration: none;
      border:solid 1px $light-gray;
      padding: rem-calc(3 16);
      background-color: $white;
      &:hover {
        border:solid 1px $primary-color;
        text-decoration: none;
      }
    }
    &.current{
      a{
       color: $white;
        background: $primary-color;
      }
    }

    &.unavailable {
      display:none!important;
      a {
        background: none;
      }
    }

    &.arrow {
      display: inline-block;
      a {
        background: $white;
        border-radius: 100px;
        border: solid 1px $primary-color;
        padding: rem-calc(3 16);
        text-align:center;
        display: block;
        @include single-transition();
        text-decoration: none;
        margin-right:3px;
        min-width: 100px;

        &:hover{
          background-color: $primary-color;
          color: $white;
          text-decoration: none;
        }
      }

      img {
        width: 29px;
      }

      span {
        display: none;
      }


    }
  }

  @media #{$small-up} {

    li {
      a {
        font-size: rem-calc(17);
        padding-left: 0.75rem;
        padding-right:  0.75rem;
      }

      &.hide {
        display: inline-block;
      }

      &.arrow {
        img {
          width: 40px;
        }

        &.prev {
          float: left;
        }

        &.next {
          float: right;
        }
      }
    }
  }

  @media #{$medium-up} {
    li {
      display: inline-block;
      &.arrow {
        span {
          display: inline;
        }
      }

      &.prev {
        img {
          margin-right: 10px;
        }
      }

      &.next {
        img {
          margin-left: 10px;
        }
      }
    }
  }
}

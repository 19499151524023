// .okayNav-header {
//   height: 7rem;
//   background-color: #fff;
// }
.okayNav a {
  color: $steel;
  font-size: rem-calc(16);
  // font-weight: bold;
  @media #{$medium} {
  	font-size: rem-calc(18);
  }
  @media #{$large} {
  	font-size: rem-calc(19);
  }
}
.okayNav a:hover {
  color: #546edb;
}
.okayNav__nav--visible a:hover {
  color: $primary-color;
}
.okayNav__nav--invisible {
  background: #fff;
}
.okayNav__menu-toggle span {
  background: $body-font-color;
}
.okayNav__menu-toggle.icon--active span {
  background: $primary-color;
}


.cercador{
  .cerca-titol-element{
    font-size:rem-calc(18);
    text-transform: none;
    a{
      text-decoration: none;
      line-height: 1.4;
    }
  }
  #main{
    @media #{$medium-up} {

      .cerca-resultats{
        border-right:solid 1px $silver;
      }
    }
    form{
      position:relative;
    }
    input.text-input{
        letter-spacing: 2px;
        border-radius: 0;
        color: $black;
        display: block;
        font-size: 1rem;
        height: 2.25rem;
        padding: rem-calc(3);
        width: 100%;
        background-color:transparent;
        text-align: left;
        border:none;
        border-bottom: .0625rem solid $black;
        box-shadow:none;
      &:active,&:focus,&:hover{
        border-color:$steel;
        background-color:transparent;
      }
      /*&::-webkit-input-placeholder {
          color: $steel;
        }
        &::-moz-placeholder {
          color: $steel;
        }
        &:-ms-input-placeholder {
          color:$steel;
        }
        &:-moz-placeholder {
          color: $steel;
        }*/
    }

    .button{
      font-size: rem-calc(14);
      border-color: $black;
      color: $black;
      background-color:transparent;
      margin-top: rem-calc(6);
      padding:rem-calc(4 8 2);
      float: right;
      border: 1px solid $primary-color;
    }
    .search-form__clear-input{
        color: $black;
        cursor: pointer;
        height: 1.5625rem;
        position: absolute;
        right: 0;
        top: .4125rem;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        width: 1.5625rem;
        background-color:transparent;
        border:none;
        outline: 0;
        padding:0;
        /*-webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        width: 1.5625rem;
        background-color:transparent;
        border:none;
        outline: 0;*/
        &:hover,&:focus{
           color: $primary-color;
        }
        &:before{
            height: .0625rem;
            left: 0;
            width: 100%;
            top: 50%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            background: currentColor;
            content: '';
            position: absolute;
        }
        &:after{
            height: 100%;
            left: 50%;
            top: 0;
            -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
            width: .0625rem;
            background: currentColor;
            content: '';
            position: absolute;
        }

    }
  }
  .cerca-resultats p{
    font-size: rem-calc(13);
  }
}
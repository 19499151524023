// .main-content {
  p, ul, ol, dl {
    // @include poblabook;
    font-size: rem-calc(16);
    @media #{$medium} {
      font-size: rem-calc(18);
    }
    @media #{$large} {
      font-size: $paragraph-font-size;
    }
  }
  a {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  // elements varis
  .pict {
    margin-bottom: $paragraph-margin-bottom*2;
  }

  .banner {
    margin-bottom: $column-gutter;
  }
  .publicitat{
    .banner{
      margin-bottom: $paragraph-margin-bottom*2;
      display: none;
      @include grid-column(12);
      &:nth-child(1){
        display: block;

      }
      @media only screen and (min-width: 480px){
        @include grid-column(6);
        &:nth-child(2){
          display: block;
        }
      }
      @media #{$large} {
        @include grid-column(4);
        &:nth-child(3){
          display: block;
        }
      }
    }
  }

  // destacat
  p.destacat {
    @include regular;
    font-size: rem-calc(20);
    line-height: 1.3;
    //margin: $paragraph-margin-bottom*2 0;
    padding: $paragraph-margin-bottom 0;
    //border-top: 4px solid #333;
    //border-bottom: 1px solid #333;
    color: #333;
    position: relative;
    &:before{
      background-color: #03A9F4;
      top: -2px;
      content: '';
      height: 6px;
      left: 0;

      position: absolute;
      width: 50%;
    }
    &:after{
      background-color: #03A9F4;
      bottom: -2px;
      content: '';
      height: 2px;
      left: 0;

      position: absolute;
      width: 25%;
    }
    @media #{$medium} {
      font-size: rem-calc(22);
      //line-height: 1.4;
      float: left;
      width:245px;
      margin-left:-40px;
      margin-right: $paragraph-margin-bottom*2;
      &.right{
        float: right;
        margin-right:-40px;
        margin-left: $paragraph-margin-bottom*2;
      }
    }
  }
  p.destacat__dreta {
    @include regular;
    font-size: rem-calc(20);
    line-height: 1.3;
    //margin: $paragraph-margin-bottom*2 0;
    padding: $paragraph-margin-bottom 0;
    //border-top: 4px solid #333;
    //border-bottom: 1px solid #333;
    color: #333;
    position: relative;
    &:before{
      background-color: #03A9F4;
      top: -2px;
      content: '';
      height: 6px;
      left: 0;

      position: absolute;
      width: 50%;
    }
    &:after{
      background-color: #03A9F4;
      bottom: -2px;
      content: '';
      height: 2px;
      left: 0;

      position: absolute;
      width: 25%;
    }
    @media #{$medium} {
      font-size: rem-calc(22);
      //line-height: 1.4;
      float: left;
      width:245px;
      float: right;
      margin-right:-40px;
      margin-left: $paragraph-margin-bottom*2;
    }
  }
  p.destacat__centrat {
    @include regular;
    font-size: rem-calc(20);
    line-height: 1.3;
    //margin: $paragraph-margin-bottom*2 0;
    padding: $paragraph-margin-bottom 0;
    //border-top: 4px solid #333;
    //border-bottom: 1px solid #333;
    color: #333;
    position: relative;
    margin:rem-calc(45 0);
    &:before{
      background-color: #03A9F4;
      top: -2px;
      content: '';
      height: 6px;
      left: 0;

      position: absolute;
      width: 50%;
    }
    &:after{
      background-color: #03A9F4;
      bottom: -2px;
      content: '';
      height: 2px;
      left: 0;

      position: absolute;
      width: 25%;
    }
    @media #{$medium} {
      font-size: rem-calc(22);





    }
  }
  div.destacat {
    padding: $paragraph-margin-bottom 0;
    border-top: 1px solid $base;
    border-bottom: 1px solid $base;
    margin-bottom: $paragraph-margin-bottom;

    .news-article__body & {
      background-color: $ghost;
      border: 0;
      padding: $paragraph-margin-bottom;

      @media #{$medium} {
        padding: $paragraph-margin-bottom*2;
      }
    }

    h2 {
      @include bold;
      text-transform: uppercase;
      font-size: rem-calc(22);
      margin-bottom: $paragraph-margin-bottom*.25;

      .news-article__body & {
        @include regular;
        text-transform: none;
        font-size: rem-calc(28);
        margin-bottom: $paragraph-margin-bottom;
      }
    }
    p {
      @include regular;
      font-size: rem-calc(22);
      line-height: 1.3;
      //margin-bottom: 0;

      .news-article__body & {
        @include thin;
        font-size: rem-calc(18);
        line-height: 1.4;
      }
    }
  }
// }

// headings
h1 {
  @include thin;
  color: $primary-color;
  font-size: rem-calc(40);
  padding-bottom: .25em;
  margin-bottom: $paragraph-margin-bottom;
  border-bottom: 1px solid $base;
  @media #{$medium} {
    font-size: rem-calc(50);
  }
  @media #{$large} {
    font-size: rem-calc(60);
  }
  &.no-border{
    border-bottom:none;
  }
  &.b{
    font-size: rem-calc(20);
    color: #444;
    text-transform: uppercase;
    padding:0;
    a{
      text-decoration:none;
    }
  }
}
h2 {
  @include semibold;
  color: $primary-color;
  font-size: rem-calc(25);
  margin-bottom: $paragraph-margin-bottom;
  @media #{$medium} {
    font-size: rem-calc(30);
  }
  @media #{$large} {
    font-size: rem-calc(35);
  }
}
h3, h4, h5 {
  margin-bottom: $paragraph-margin-bottom;
}
.small-title {
  @include semibold;
  color: $black;
  font-size: rem-calc(16);
  line-height: 1.2;
  text-transform: uppercase;
  display: block;

  &--brdr {
    border-bottom: 1px solid $base;
    padding: 0 0 $paragraph-margin-bottom / 2;
  }

  &--especial {
    border: none;
    color:$white;
    padding:0;
    float: left;
    margin: 0;
    a {
      @include hover;
      color: $white;
    }
  }
}

// imatge esquerra/dreta
.img-left,
.img-right {
  img {
    margin-bottom: $paragraph-margin-bottom;
  }
}
@media #{$medium-up} {
  .img-left {
    img {
      float: left;
      margin-right: $column-gutter/2;
    }
  }

  .img-right {
    img {
      float: right;
      margin-left: $column-gutter/2;
    }
  }
}

// llistes
// .main-content {
  ul:not(.pagination,.destacat,.people,.llistat-noticies,.cards,.llistat-noticies-especials,.llistat-especials,.llistat-opinions,.related-links-list,.box__actions,.box__list, .box--options__list, .blocks-list, .share-icons, .social, .slick-dots) {
    list-style: none;
    margin-left: $column-gutter/2;

    li {
      position: relative;
      padding-left: 1em;
      margin-bottom: 0.25em;

      &:before {
        content: '';
        background: $primary-color;
        display: block;
        width: 9px;
        height: 9px;
        position: absolute;
        left: 0;
        top: 0.55em;
      }

      p {
        margin-bottom: 0;
      }

      // subllista 1
      ul {
        margin-left: 0;

        li {
          &:before {
            width: 8px;
            height: 8px;
            border-radius: $global-rounded;
            top: 0.6em;
          }

          p {
            font-size: inherit;
          }

          // subllista 2
          ul {
            margin-bottom: 0;
            margin-top: 0.5em;
            font-size: rem-calc(14);

            li {
              &:before {
                width: 7px;
                height: 7px;
                border-radius: $global-rounded;
                top: 0.6em;
                border: 2px solid $primary-color;
                background: none;
              }

              p {
                font-size: inherit;
              }
            }
          }
        }
      }
    }

    // llistat dues columnes
    &.duesCol {
      @media #{$medium-up} {
        @include block-grid(2);
        margin: 0 0 $paragraph-margin-bottom 0;

        li {
          padding-left: 1em;
          padding-bottom: 0;
        }
      }
    }

    // llista amb fons de color
    &.llista_fons {
      margin-left: 0;

      li {
        background-color: $secondary-color;
        padding: 0.5em 30px;
        margin-bottom: 0.5em;

        &:before {
          top: 1.05em;
          left: 14px;
        }
      }
    }

    // llista separadors
    &.llista_separadors {
      margin-left: 0;

      li {
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        border-bottom: 1px dotted $secondary-color;
        margin-bottom: 0;

        &:before {
          top: 1.05em;
        }
      }
    }

    // llista caixa
    &.llista_caixa {
      margin-left: 0;
      background: $secondary-color;
      padding: 8px;

      li {
        padding: 0.5em 30px;
        margin-bottom: 0.55em;
        background-color: $white;

        &:last-child {
          margin-bottom: 0;
        }

        &:before {
          top: 1.05em;
          left: 14px;
        }

        &.caixaTitol {
          color: $white;
          background: $primary-color;
          color: $white;
          font-size: rem-calc(17);
        }
      }
    }
  }

  ol {
    margin-left: $column-gutter;
  }
// }

// botons
.btn,
.button {
  @include button($padding: $button-sml);

  a {
    color: $white;
    text-decoration: none;
  }
}

a.button {
  text-decoration: none;
}
.boto-mes{
  text-align: center;
  margin:rem-calc(30 0 60);
  a{
    border-radius: 100px;
    border: solid 1px $primary-color;
    padding: rem-calc(8 16);
    min-width: 200px;
    display: inline-block;
    @include single-transition();
    text-decoration: none;
    font-size: rem-calc(18);
    &:hover{
      background-color: $primary-color;
      color: $white;
    }
  }
}
// baixar arxiu
.dl {
  background: $secondary-color;
  padding: 0.5em 1em;
  border-radius: 12px;

  a {
    @include hover;
  }

  &:before {
    @extend .icon-download;
    content: '';
    display: inline-block;
    vertical-align: text-bottom;
    margin-right: 10px;
  }
}

// vincle destacat
.dest {
  background: $secondary-color;
  padding: 0.5em 1em;
  border-radius: 12px;

  a {
    @include hover;
  }

  &:before {
    content: '';
    display: inline-block;
    margin-right: 5px;
    width: 10px;
    height: 10px;
    background: $primary-color;
    border-radius: 3px;
  }
}
.people-box{
  margin: rem-calc(45) 0 0;
  ul.people{
    @include block-grid(1);
     img{
        max-width:100px;
      }
    @media only screen and (min-width: 30.0625em){
      @include block-grid(2);
      &.col4{
        @include block-grid(2,4em);
        li{
          padding-bottom: 2em;

        }
      }
    }
    @media #{$medium-up} {
      @include block-grid(3);
      img{
        max-width:200px;
      }
      &.col4{
        @include block-grid(4,4em);
        li{
          padding-bottom: 2em;

        }
      }
    }
    li{
        h3{
            margin:rem-calc(10 0 5);
            font-size: rem-calc(22);
        }

        p{
          font-size: rem-calc(16);
          &.carrec{
              font-size: rem-calc(16);
              margin:0 0 1rem;
              @include poblabook-italic;
          }
          &.boto-mes{
            text-align: left;
            a{
              font-size: 0.75em;
              text-align: center;
            }
          }
        }
    }
  }
}
.people-box + .people-box{
    margin-top:0;
}
.contingut-noticia div{
   margin-bottom:rem-calc(16);
}
ul.destacat{
  @include block-grid(1);
  @media #{$medium-up} {
    @include block-grid(3,40px);
  }
  background-color: #f6f6f6;
  li{
    text-align: center;

    a{
      background-color:#fff;
      margin-top: 1em;
      display: block;
      font-size: rem-calc(26);
      padding:2em;
      color:$primary-color;
      text-decoration: none;
      text-transform:uppercase;
      box-shadow: 0 5px 10px rgba(0,0,0,.15);
       position:relative;
       @include single-transition();
       border:solid 2px transparent;
       color: #000;
      &:after{
        content: '';
        background: url('img/fletxa.svg') 0 0 no-repeat;
        width: 24px;
        height: 24px;
        position: absolute;
        bottom:15px;
        right:15px;
      }
      &:hover{
        border-color:#000;

      }

    }
  }
}
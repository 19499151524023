.agenda {
  &__bg {
    background: $smoke;

    background-size: cover;
    margin: rem-calc(-30) 0 0;
    padding: rem-calc(40 0);
    &.b{
      margin-bottom:0;
    }
  }
  .actualidad-agenda{
      //background-color: $ghost;
      padding: rem-calc(30 25 0 0);
    }
  table.cal{

      width: 100%;
      border:none;
      border-bottom:solid 2px $black;
          box-shadow: 0 5px 10px rgba(0,0,0,.15);
      caption{
        padding-bottom:rem-calc(8);
      }

    tr{
      background-color:$white;

    }
  }
  .cards {

      a{

        .data{
          position:absolute;
          top:-25px;
          background-color:$steel;
          padding:rem-calc(5 12);
          text-align: center;
          @include single-transition();
          .dia{
             font-size: rem-calc(20);
             font-weight:bold;
             color:$white;
          }
          .mes{
           color:$white;
           font-size: rem-calc(16);
          }
        }
        &:hover{
            .data{
              background-color:$black;
            }
        }

      }
  }

}

.slick-container {
    background-color: #002947;
    padding: $paragraph-margin-bottom*2 0 $paragraph-margin-bottom;

    .small-title {
        color: $white;
        text-align: center;
        margin-bottom: $paragraph-margin-bottom*2;
    }
}
.slider {
    margin-top: 0;
    &__container {
        padding: 0 $column-gutter / 2;
        position: relative;

        a {
            &:hover,
            &:focus {
                .slider__info {
                    top: 0;
                    background-color: $primary-color;
                }
            }
        }
    }
    &__info {
        position: absolute;
        left: $column-gutter/2;
        right: $column-gutter/2;
        bottom: 0;
        background-color: $primary-color;
        background-color: rgba($primary-color, .7);
        padding: $column-gutter/4 $column-gutter/2;
        transition: background-color .1s ease-in;
        @media #{$large-up} {
            top: 50%;
            transition: top .1s ease-in;
            padding-top: $column-gutter/2;
            padding-bottom: $column-gutter/2;
        }
    }
    &__date {
        color: $white;
        font-size: rem-calc(14);
        display: block;
        @media #{$medium-up} {
            font-size: rem-calc(16);
        }
    }
    &__title {
        color: $white;
        font-size: rem-calc(20);
        margin-bottom: 0;
        @media #{$medium-up} {
            font-size: rem-calc(22);
        }
        @media #{$large-up} {
            font-size: rem-calc(24);
        }
    }
}

// dots
.slick-dots {
    button {
        margin-bottom: 0;
    }
}


// botons prev/next
@media #{$small-only} {
    .slick-prev {
        left: -15px;
    }
    .slick-next {
        right: -15px;
    }
}

// .slick-arrow {

//     // ocultar a mòbil
//     @media #{$small-only} {
//         display: none !important;
//     }

//     border-radius: $global-rounded;
//     text-align: center;

//     &:before {
//         content: '';
//         display: inline-block;
//     }
// }

// @media #{$medium-only} {
//     .slick-prev {
//         left: -90px;

//     }
//     .slick-next {
//         right: -90px;
//     }
// }

// @media #{$medium-up} {
//     .slick-prev {
//         left: -95px;

//     }
//     .slick-next {
//         right: -95px;
//     }
// }


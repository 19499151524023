.menu{
    background:$secondary-color;
  }

.main-nav {
    @media #{$medium-up} {
        @include clearfix;
        background-color: #fafafa;
        //margin-bottom: 1.875rem;
        text-align: center;
       // max-height: 68px;
        .menu-wrapper {
            @include grid-row;
          }

    }
    h2 {
        @include visually-hidden;
      }
  }
  .menu-button {
    background-color: $secondary-color;
    outline: 0;
    width: 100%;
    position: relative;
    right: 0;
    top: 0;
    .hamburger-box{
        padding: 0;
        width: 36px;
        height: 32px;
        position: absolute;
        background-color: rgba(62,48,41,.4);
        top: -75px;
        right: 10px;

    }
    @media #{$medium-up} {
      display: none;
    }
  }
  .hamburger--3dx .hamburger-inner {
        margin-left: 7px;
  }

  .site-menu {
    list-style: none;
    text-align: center;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
    //font-size: rem-calc(16);
    margin: 0 0 -6px 0 !important;
    font-size: 1.1875rem;
    float: none;
    @media #{$medium-up} {
        float: left;
    }

    @include grid-column;
    li {
      @media #{$medium-up} {
        display: inline-block;
        margin-left: 1em;
        margin-right: 1em;
        margin-bottom: 0;
        padding: 0;

      }

      &::before{
        display: none;
      }

      &.active {
        a {
            color: #005a9b;
            &::after{
              width: 100%;
            }
        }
      }

    a {

        color: $steel;
        font-size: rem-calc(16);
        text-decoration: none;
        position: relative;
        display: block;
        padding: 15px 15px 25px;

        &::after{
            position: absolute;
            content: '';
            background-color:  #005a9b;
            width: 0%;
            height: 10px;
            bottom:6px;
            left: 0;
        }
        &:hover,
        &.is-hovered {
            color: #005a9b;
            &::after{
                background-color:  #005a9b;
                width: 100%;
                transform: scaleX(0);
                animation: grow 0.25s forwards;
                transform-origin: center;
            }
        }
        @keyframes grow {
          0% {
            transform: scaleX(0);
          }
          100% {
            transform: scaleX(1);
          }

        }
        @media #{$medium} {
            font-size: rem-calc(18);
        }
        @media #{$large} {
            font-size: rem-calc(19);
        }
    }

    }
  }




@media #{$small-only} {
  .menu-wrapper {
      animation: .05s fade-in .1s linear forwards;
      bottom: 0;
      left: 0;
      opacity: 0;
      overflow: auto;
      margin-top: 70px;
      position: fixed;
      right: 0;
      top: 0;
      min-height: auto;
      overflow: hidden;
      display: none;
      z-index: 10;
      ul{
        float: none;
        padding: 0;
        li{
            padding: 0;
            margin: 0;
            &.active{
                a{
                    background-color:  #005a9b;
                    color: #fff;

                }
            }
            a {
                font-size: 1rem;
                text-decoration: none;
                position: relative;
                display: block;
                padding: 6px 0px 10px;
                &::after{
                    position: absolute;
                    content: '';
                    background-color:  #b4b2b2;
                    width: 100%;
                    height: 1px;
                    bottom:0;
                    left: 0;
                }
            }
        }
    }
  }
  .mobile-tools {
    display: none;
    position: relative;
    z-index: 10;
    width: 100%;
  }
  .js-menu-open .menu-wrapper {
    display: block;
    position: relative;
     margin-top: -23px;
     z-index: 10;
     background-color: $secondary-color;
  }
  .js-menu-open .mobile-tools {
    display: block;
  }
  .main-header{
    width: 100%;
    background-color: $secondary-color;
    top:0;
    max-width: 100%;

  }
  .main-content{
    @media #{$medium-up} {
      margin-top:70px;
    }
    .home & {
      margin-top: 0;
    }
  }

}
@keyframes fade-in{0%{opacity:0}100%{opacity:1}}

.list{
  .news-item:last-child{
    border-bottom:none;
     padding-bottom:0;
  }
}
.destacades-model {
  .principal {
      padding-left: .9375rem;
      padding-right: .9375rem;
    }
    .secundaria {
      padding-left: .9375rem;
      padding-right: .9375rem;
      h2{
        //font-size: rem-calc(18);
      }
      p.news-item__text{
        display: none;
      }
    }
    .news-item{
      border-bottom:none;
      padding-bottom:0;
    }
  @media #{$medium} {
    display: flex;
    -webkit-flex-flow: row wrap;
    flex-flow: row wrap;
    justify-content: space-around;
    .news-item{
      background-color: $ghost;
      height: 100%;
      .contingut{
        padding:0 rem-calc(12);
      }
    }
    .principal {
      padding-left: .9375rem;
      padding-right: .9375rem;
    }
    .secundaria {
      padding-left: .9375rem;
      padding-right: .9375rem;
      h2{
          font-size: rem-calc(24);
      }
      p.news-item__text{
          display: block;
           font-size: rem-calc(17);
      }

    }

  }

  @media #{$large} {

  }
  .home &{
    margin-bottom: rem-calc(40);
  }


}
.destacades-model__1 {
  .news-item {
    border-bottom:solid 3px $ghost;
    margin-bottom: rem-calc(20);
    padding-bottom: rem-calc(20);
  }
  @media only screen and (min-width: em-calc(600)) and (max-width: em-calc(800)) {
    display: flex;
    -webkit-flex-flow: row wrap;
    flex-flow: row wrap;
    justify-content: space-around;
    .news-item{
      background-color: $ghost;
      height: 100%;
      .contingut{
        padding:0 rem-calc(12);
      }
    }
    .principal {
      flex: 2 1 auto;
      width:100%;
      margin-bottom: rem-calc(40);
      .news-item__img{
        width: 100%;
      }
    }
    .secundaria {
      flex: 1 1 auto;
      width:25%;
    }
  }

  @media #{$medium} {
    .principal {
      flex: 2 1 auto;
      width:50%;
      padding:0;
      .news-item__img{
        width: 100%;
      }
    }
    .secundaria {
      flex: 1 1 auto;
      width:25%;
      float:left;
    }

  }

}/*
.destacades-model__1 {
  .news-item {
    border-bottom:solid 3px $ghost;
    margin-bottom: rem-calc(20);
    padding-bottom: rem-calc(20);
  }
  @media #{$medium} {
    .principal {
      flex: 2 1 auto;
      width:50%;
      padding:0;
    }
    .secundaria {
      flex: 1 1 auto;
      width:25%;
      float:left;
    }

  }
}*/
.destacades-model__2 {
  @media #{$medium} {
    .principal {
      width:75%;
    }
    .secundaria {
      width:25%;
    }
  }
}

.destacades-model__3 {
  .news-item{
    background-color: #fff;
    height: auto;
    border-bottom:solid 3px $ghost;
    margin-bottom: rem-calc(20);
    padding-bottom: rem-calc(20);
  }
  @media #{$medium-up} {
    .principal {
      width:75%;
    }
    .news-item{
      background-color: #fff;
      height: auto;
      border-bottom:none;
    }
    .secundaria {
      width:25%;
      h2{
          font-size: rem-calc(20);
      }
      p.news-item__text{
        display: none;

      }
      .news-item{
        border-bottom:solid 3px $ghost;
        &:last-child{
          border-bottom:none;
        }
      }
    }
    @media #{$xlarge-up} {
      .secundaria {
        .news-item{

             p.news-item__text{
                display: block;
              }

        }
      }
    }

  }
}

.secundaries {
    .news-item {
        &__title {
            margin-bottom: .625rem;
        }
        &__text {
            display: block;
            font-size: rem-calc(17);
        }
    }
}

.main-footer {
  margin-top: $paragraph-margin-bottom*3;

  .especials & {
    margin-top: 0;
  }
  .agenda & {
    margin-top: 0;
  }
  .subportada & {
    margin-top: 0;
  }

  // layout footer
  &__contact-wrapper,
  &__social-wrapper,
  &__links-1-wrapper {
    width: 50%;
    float: left !important;
    clear: left;
  }
  &__nav-wrapper {
    width: 50%;
    float: right !important;
  }
  @media only screen and (min-width: em-calc(600)) {
    &__contact-wrapper,
    &__social-wrapper,
    &__links-1-wrapper {
      width: 33.333%;
    }
    &__nav-wrapper {
      width: 66.666%;
    }
  }
  @media only screen and (min-width: em-calc(1000)) {
    &__floor-1 .row {
      max-width: 55rem;
    }
    &__contact-wrapper {
      right: 33.333%;
      width: 25%;
      clear: none;
    }
    &__nav-wrapper {
      left: 25%;
      width: 33.333%;
      float: left !important;
    }
    &__links-1-wrapper {
      right: 24%;
      width: 17%;
      clear: none;
    }
    &__social-wrapper {
      left: 17%;
      width: 24%;
      clear: none;
    }
  }
  // /layout footer

  a {
    @include hover;
  }
  &__floor-1 {
    color: $white;
    background-color: $primary-color;
    padding: rem-calc(30 0);
    a {
      color: $white;
    }
  }
  &__floor-2 {
    background-color: $black;
    padding: rem-calc(20 0);
    text-align: center;
  }
  &__logo {
    margin-bottom: rem-calc(12);
  }
  &__address {
    font-size: rem-calc(14);
  }
  &__contact {
    font-size: rem-calc(14);
    @include bold;
  }
  &__links-1 {
    @include undolist;
    font-size: rem-calc(14);
    margin-left: 0 !important;
  }
  &__nav {
    @include undolist;
    margin-left: 0 !important;
    @include semibold;
    font-size: rem-calc(14);

    a {
      display: block;
      padding: .6em 0 .2em;
      border-bottom: 1px solid rgba(255,255,255,.25);
      transition: border-bottom-color .1s ease-in;

      &:hover {
        text-decoration: none;
        border-bottom-color: rgba(255,255,255,.5);
      }
    }

    @media only screen and (min-width: em-calc(600)) {
      column-count: 2;
      column-gap: $column-gutter;
    }
  }
  &__copy {
    font-size: rem-calc(14);
    color: $white;

    @media #{$medium} {
      display: inline;
    }
  }
  &__links-2 {
    @include undolist;
    font-size: rem-calc(14);
    color: $white;
    li {
      display: inline;
      &:after {
        content: '\2758';
        display: inline;
      }
      &:last-child {
        &:after {
          content: '';
        }
      }
    }
    a {
      color: #7bcbe6;
    }

    @media #{$medium} {
      display: inline;
    }
  }
}

.blocks-list {
  @include undolist;
  margin-left: 0;
  font-size: rem-calc(22);

  a {
    display: block;
    margin-bottom: $paragraph-margin-bottom;
    color: $black;
    background: $ghost;
    padding: $paragraph-margin-bottom;
    text-decoration: none;
    transition: background-color .1s ease-in;
    line-height: 1.3;
    position: relative;

    &:hover {
      background-color: darken($ghost, 5%);
    }

    &:before {
      @include sprite($cuadrat-mes-relacionades);
      content: '';
      display: block;
      top: 0;
      right: 0;
      position: absolute;
    }
  }
}
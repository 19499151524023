.llistat-noticies {
    @include undolist;
    @include block-grid(2);
    margin-left:0;
    @media only screen and (min-width: em-calc(600)) and (max-width: em-calc(800)) {
      @include block-grid(3);
      margin-left:0;
      li:last-child{
        display: none;
      }

    }
    @media #{$medium} {
      @include block-grid(4,rem-calc(60));
      margin-left:0;

    }
}
.llistat-noticies-especials {
    @include undolist;
    @include block-grid(1);
    margin-left:0;
    @media #{$medium} {
      //@include block-grid(2,rem-calc(60));
      @include block-grid(2);

    }
    .news-item{
      margin:0;
    }
    .contingut{
      padding: rem-calc(12);
      min-height: 100px;
    }
}
.llistat-especials {
    @include undolist;
    @include block-grid(2);
    margin-left:0;

    @media #{$medium-up} {
      //@include block-grid(3,rem-calc(60));
      @include block-grid(3);
      margin-left:0;

    }
    .banner{
      text-align: center;
    }
}

.cards {
    @include undolist;
    @include block-grid(1);
    margin-left:0;

    @media #{$medium-up} {
      @include block-grid(2,rem-calc(60));
      margin-left:0;

    }

    a{
      position: relative;
      background: #fff;
      border-radius: 3px;
      transition: box-shadow .2s linear 0s,transform .2s linear 0s;
      padding: rem-calc(35 20 10);
      margin-bottom:rem-calc(25);
       text-decoration:none;
        color:#000;
        display: block;
      h2{
        font-size: rem-calc(24);
        color:#000;
      }
      p{
        font-size: rem-calc(16);
      }
      &:hover{
          box-shadow: 0 8px 16px 0 rgba(45,45,55,.25);
          transform: translateY(-10px);
      }

    }
}
.llistat-opinions {
    @include undolist;
    @include block-grid(1);
    margin-left:0;

    @media #{$medium} {
      @include block-grid(2,rem-calc(60));
      margin-left:0;
      &.experts,&.articles{
        @include block-grid(3,rem-calc(60));
        margin-left:0;
      }
    }
    a{
        background-color: $ghost;
        padding: rem-calc(12);
        display: block;
        text-decoration: none;
        color: $steel;
        text-align:center;
        @include single-transition();
        border-left:solid 4px $ghost;

        img{
          margin:0 auto rem-calc(20);
          max-width: 50px;
        }
        div{
          font-size: rem-calc(15);
          &.tema{
            text-transform: uppercase;
            font-weight: 600;
          }
          &.autor span{
            font-size: 70%;
            display: block;
          }
        }
        h3,.titol{
          font-size: rem-calc(22);
          color: $steel;
          margin:rem-calc(6 0 0);
           @include single-transition();
        }
        &:hover{
          background-color: $smoke;
          border-color:$steel;
          color:$black;
          h3,div{
            color:$black;
          }
        }
        @media #{$large} {
          padding: rem-calc(25);
          min-height: 250px;
          img{max-width: 75px;}
        }
    }
    &.editorials{
      a{
        background-color: darken($primary-color,10%);
        color: $white;
        border-left:solid 4px darken($primary-color,10%);
        &:hover{
          background-color: darken($primary-color,25%);
          color:$white;
          h3,div{
            color:$white;
          }
        }
      }
      div{
        color: $white;
      }
      h3,.titol{
        padding-left:rem-calc(0);
        color: $white;
      }
      @media #{$large} {
        h3,.titol{
          margin: rem-calc(30 0);
        }
        &.llista{
          //@include block-grid(3,rem-calc(60));
          margin-left:0;
          a{
            min-height: 150px;
          }
        }
      }

    }
}


.llistat-experts {
    @include undolist;
    @include block-grid(2);
    margin-left:0!important;

    @media #{$medium} {
      @include block-grid(4,rem-calc(60));
      margin-left:0;
    }
    text-align:center;

    a{

        padding: rem-calc(12 0);
        display: block;
        text-decoration: none;
        color: $steel;
        text-align:center;
        @include single-transition();
        border:solid 1px $ghost;
        position:relative;
        overflow: hidden;
        box-shadow:0px 7px 11px -5px rgba(0, 0, 0, 0.3);
        .autor{
          font-size: rem-calc(18);
          margin: rem-calc(5 0);
        }
        .articles{
          color:$primary-color;
           font-size: rem-calc(14);
        }
        .tema{
          font-size: rem-calc(14);
          position:absolute;
          bottom:0;
          background-color:$primary-color;
          width: 100%;
          color:#fff;
          height: 100%;
          padding:rem-calc(10);
          @include single-transition();
          opacity: 0;
          left:-300px;
          strong{
            font-size: rem-calc(18);
          }
        }
        &:hover{
          .tema{
             opacity: 1;
             left:0;
          }
        }
        @media #{$large} {

          min-height: 150px;

        }
    }


}
.news-article {
  figure {
    margin: 0 0 $paragraph-margin-bottom*2;
    text-align: center;

  }

  figcaption {
    font-size: rem-calc(13);
    margin-top: rem-calc(8);
    border-bottom: 1px solid $iron;
    padding-bottom:  rem-calc(8);
    @media #{$small-up} {
      font-size: rem-calc(14);
    }

    @media #{$xlarge-up} {
      font-size: rem-calc(15);
    }
  }
  &__meta {
    border-bottom: 1px solid $base;
    padding-bottom: $paragraph-margin-bottom / 2;
    margin-bottom: $paragraph-margin-bottom;
    position: relative;

    .especial__bg & {
      @include clearfix;
      border-bottom-color: $white;
    }
    &.b{
      margin-bottom:0;
    }

    @media #{$medium} {
      margin-bottom: $paragraph-margin-bottom*2;
    }
  }
  &__categ {
    a {
      @include hover;
    }
    time {
      @include regular;
    }
  }
  &__title {
    text-align: center;
    padding-bottom: 0;
    border: 0;
    font-size: rem-calc(30);
    margin: 0 auto $paragraph-margin-bottom;
    @media #{$medium} {
      font-size: rem-calc(36);
      width: 70%;
    }
    @media #{$large} {
      font-size: rem-calc(44);
    }
    &.b{
      margin-bottom: $paragraph-margin-bottom*2;
      color: #444;
      @media #{$medium} {
        font-size: rem-calc(36);
        width: 70%;
      }
      @media #{$large} {
        font-size: rem-calc(44);
      }
    }
    &.life{
      margin-bottom: 0;
      background-color:#414141;
      width: 100%;
      padding: $paragraph-margin-bottom*2 $paragraph-margin-bottom*3;
      color:#fff;
    }
  }
  .agenda  {
    .news-article__title{
      text-align: left;
      width:100%;
    }
    .destacat{
      background-color: $ghost;
      padding-left: 1rem;
      padding-right: 1rem;
      time{
        padding: 0;
        &:after{
          content: none;
        }
      }
      .news-article__lead{
        font-size: rem-calc(18);
      }
    }
    .news-article__lead{
      margin-bottom:1rem;
      text-align: left;
    }
  }
  &__interviewed {
    text-align: center;
    padding-bottom: 0;
    border: 0;
    font-size: rem-calc(18);
    margin: 0 auto $paragraph-margin-bottom*2;
    background-color:lighten($primary-color,60%);
    padding: rem-calc(12);
    //color:$white;
    span{
      display: block;
    }
    @media #{$medium} {
      font-size: rem-calc(32);
      font-weight: 600;
       padding: rem-calc(22);
      span{
         font-size: rem-calc(18);
         font-weight: 400;
      }
    }
  }
  &__lead {
    text-align: center;
    font-size: rem-calc(18);
    margin-bottom: $paragraph-margin-bottom*2;
    @media #{$medium} {
      font-size: rem-calc(22);
    }
    &.life{
      margin-bottom: 0;
      background-color:#414141;
      width: 100%;
      padding:0 $paragraph-margin-bottom*3 $paragraph-margin-bottom*2;
      color:#fff;
      position:relative;
      &:before{
        content: '';
        position:absolute;
        top:-21px;
        width: 100px;
        left: 50%;
        margin-left: -50px;
        background-color:#fff;
        height: 1px;
      }
    }
  }
  time{
    position:relative;
    padding-bottom:rem-calc(16);
    margin-bottom:rem-calc(16);
    display: block;
    &:after{
      background-color: #03A9F4;
      bottom: -2px;
      content: '';
      height: 2px;
      left: 0;
      position: absolute;
      width: 60px;
    }

  }
  &__subtema{
      text-align: center;
      text-transform: uppercase;
      margin-bottom: rem-calc(12);
      font-weight: 600;  }
  &__autor{
       /* &.firma{
            &:before{
            background-color: #03A9F4;
            top: 22px;
            content: '';
            height: 2px;
            left: 50%;
            margin-left: -30px;
            position: absolute;
            width: 60px;
            }
        }*/
      &.opinio{
          h2{
            margin: rem-calc(8 0);
            a{
              text-decoration:none;
              color:#000;
            }
          }
          p{
            font-size: rem-calc(15);
            font-style: italic;
            a{
              font-size: rem-calc(13);
               font-style: normal;
            }
          }
      }
  }
  .time{
      font-size: rem-calc(15);
      time{
        padding-top:rem-calc(16);
        &:after{
          top: -2px;
          left: 0%;
        }
      }
      &.firma{
        time{
            &:after{
            top: -2px;
            left: 50%;
            margin-left: -30px;
            }
        }
}

  }
  /*&__img {
    margin-bottom: $paragraph-margin-bottom*2;
  }*/
  &__body {
    @include poblabook;

    h2 {
      @include bold;
      font-size: rem-calc(22);
      color: $black;
    }
    p + h2{
      margin-top: $paragraph-margin-bottom*2;
    }
  }
  &__intro {
    @include poblabold;
    font-size: rem-calc(19);
    @media #{$medium} {
      padding-right: rem-calc(40);
    }
    &.b{
      margin-bottom: $paragraph-margin-bottom*2;

    }
  }
}

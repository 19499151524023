.news-item {

  padding-bottom: $paragraph-margin-bottom*2;
  margin-bottom: $paragraph-margin-bottom*2;
  border-bottom: 3px solid $ghost;
  &__img {
    margin-bottom: $paragraph-margin-bottom;
  }
  &__meta {
    display: block;
    font-size: rem-calc(12);
    line-height: 1.2;
    text-transform: uppercase;
    margin-bottom: $paragraph-margin-bottom / 2;
    a {
      @include hover;
    }
  }
  &__title {
    @include semibold;
    margin-bottom: $paragraph-margin-bottom / 2;
    a {
      @include hover;
      color: $black;
    }

    .news-item--highlight & {
      a {
        color: $white !important;
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        padding: $paragraph-margin-bottom*.5 $paragraph-margin-bottom;
        background-color: $black;
        background-color: rgba(0, 0, 0, .6);
        @include single-transition();

        &:hover {
          background-color: rgba(0, 0, 0, 1);
          text-decoration: none;
        }
      }
    }
  }
  &__text {
    @include poblabook;
    margin-bottom: 0;
  }

  // .news-item amb imatge esquerra
  &--img-esq {
    @media only screen and (min-width: em-calc(500)) {
      .news-item__img-container {
        @include grid-column($columns: 4);
      }
      .news-item__txt-container {
        @include grid-column($columns: 8);
        // padding-left: 0;
      }
    }
  }

  // .news-item destacat
  &--destacat {
    background-color: $ghost;
    border-bottom: 0;
    padding-bottom: $paragraph-margin-bottom;
    position: relative;

    .news-item__title {
      font-size: rem-calc(21);
      a {
        color: $steel;
      }
    }

    @media #{$medium} {
      min-height: 470px;
      .news-item__img-container {
        @include grid-column($columns: 12);
      }
      .news-item__txt-container {
        @include grid-column($columns: 12);
      }
    }

    @media only screen and (min-width: em-calc(600)) and (max-width: em-calc(800)) {
      padding-bottom: 0;
      .news-item__img {
        margin-bottom: 0;
      }
    }
  }

  // .news-item destacat-2
  &--destacat-2 {
    @media only screen and (min-width: em-calc(600)) {
      padding-bottom: 0;
      .news-item__wrapper {
        padding-left: 0;
      }
      .news-item__img-container {
        @include grid-column($columns: 6);
      }
      .news-item__txt-container {
        @include grid-column($columns: 6);
        padding-left: 0;
      }
      .news-item__img {
        margin-bottom: 0;
      }
    }
    @media only screen and (min-width: em-calc(800)) {
      .news-item__wrapper {
        padding-top: $paragraph-margin-bottom;
        padding-bottom: $paragraph-margin-bottom;
      }
    }
  }

  &__wrapper {
    padding: 0 $paragraph-margin-bottom;

    @media only screen and (min-width: em-calc(600)) and (max-width: em-calc(800)) {
      padding-top: $paragraph-margin-bottom;
      padding-bottom: $paragraph-margin-bottom;
    }
  }

  // .news-item secundari
  &--secundari {
    border-bottom: 0;
    padding-bottom: 0;

    .news-item__title {
      margin-bottom: 0;
      font-size: rem-calc(16);
      @media #{$medium} {
        font-size: rem-calc(18);
      }
      a {
        color: $steel;
      }
    }
  }
  &--estil {
    min-height: 120px;
  }
  // highlight
  &--highlight {
    padding-bottom: 0;
    min-height: 120px;
    img{
       min-width: 100%;
    }
    .news-item__title {
      margin-bottom: 0;
      font-size: rem-calc(21);

      @media #{$medium} {
        font-size: rem-calc(24);
      }

      @media #{$large} {
        font-size: rem-calc(30);
      }

      a {
        color: $steel;
        padding: .5em $paragraph-margin-bottom;

        @media #{$medium} {
          padding-top: .75em;
          padding-bottom: .75em;
        }
      }
    }
  }
  &--estil {
    h2.news-item__title {
      padding: 0 rem-calc(10);
    }
    @media #{$medium} {
      &.b{
        min-height: 450px;
      }
    }
  }
  // text categoria
  &__categ {
    position: absolute;
    left: 0;
    top: 0;
    text-transform: uppercase;
    font-size: rem-calc(15);
    background-color: $black;
    background-color: rgba(0,0,0,.6);
    text-decoration: none;
    color: $white;
    transition: background-color .1s ease-in;
    padding: $paragraph-margin-bottom*.5 $paragraph-margin-bottom;
    @include single-transition();
    &:hover {
      background-color: $black;
      color: $white;
    }
  }
}
.news-wrapper {
  //border-top: 6px solid $ghost;
  border-bottom: 6px solid $ghost;
  //padding-top: $paragraph-margin-bottom*2;
  margin-bottom: $paragraph-margin-bottom*2;
  &__llistat {
    border:none;
    margin-bottom: 0;
  }
}
.news-wrapper__llistat {
  padding-top: $paragraph-margin-bottom*2;
}

// layout estil
.estil-wrapper {
  @media only screen and (min-width: em-calc(500)) {
    @include grid-column(6);
  }
}

// layout notícies relacionades
.related-news__wrapper {
  @media only screen and (min-width: em-calc(500)) {
    @include grid-column(6);
  }
}
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 300;
  src: local('Titillium WebLight'), local('TitilliumWeb-Light'), url('../fonts/Titillium Web/Titillium-WebLight.woff') format('woff'), url('../fonts/Titillium Web/Titillium-WebLight.woff2') format('woff2'), url('../fonts/Titillium Web/Titillium-WebLight.ttf') format('truetype');
}

@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/Titillium Web/Titillium-Web.eot');
  src: local('Titillium Web'), local('TitilliumWeb-Regular'), url('../fonts/Titillium Web/Titillium-Web.woff') format('woff'), url('../fonts/Titillium Web/Titillium-Web.woff2') format('woff2'), url('../fonts/Titillium Web/Titillium-Web.eot?#iefix') format('embedded-opentype'), url('../fonts/Titillium Web/Titillium-Web.svg#TitilliumWeb') format('svg'), url('../fonts/Titillium Web/Titillium-Web.ttf') format('truetype');
}

@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 600;
  src: local('Titillium WebSemiBold'), local('TitilliumWeb-SemiBold'), url('../fonts/Titillium Web/Titillium-WebSemiBold.woff') format('woff'), url('../fonts/Titillium Web/Titillium-WebSemiBold.woff2') format('woff2'), url('../fonts/Titillium Web/Titillium-WebSemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 700;
  src: local('Titillium WebBold'), local('TitilliumWeb-Bold'), url('../fonts/Titillium Web/Titillium-WebBold.woff') format('woff'), url('../fonts/Titillium Web/Titillium-WebBold.woff2') format('woff2'), url('../fonts/Titillium Web/Titillium-WebBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Titillium Web';
  font-style: italic;
  font-weight: 400;
  src: local('Titillium WebItalic'), local('TitilliumWeb-Italic'), url('../fonts/Titillium Web/Titillium-WebItalic.woff') format('woff'), url('../fonts/Titillium Web/Titillium-WebItalic.woff2') format('woff2'), url('../fonts/Titillium Web/Titillium-WebItalic.ttf') format('truetype');
}


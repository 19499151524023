.especial {
  &__bg {
    background: $steel;
    background-size: cover;
    margin: rem-calc(-30) 0 $paragraph-margin-bottom*2;
    &.b{
      margin-bottom:0;
    }
  }
  .opacity{
    background:rgba($black,.35);
    @include single-transition();
    position:relative;
    padding: $paragraph-margin-bottom*2 0;

     @media #{$large} {
        min-height: 550px;
      }
    &.hover{
      background:rgba($black,.75);
    }
    a{
      //color: $white;
      text-decoration: none;
      display: inline-block;
    }
    .more {
      @include sprite($triangles-especials);
      display: block;
      margin-top: -32px;
      margin-bottom: $paragraph-margin-bottom;
    }
  }
  &__share {
    @include clearfix;
    border-bottom: 1px solid $white;
    padding-bottom: $paragraph-margin-bottom / 2;
    margin-bottom: $paragraph-margin-bottom / 2;
  }
  &__pretitle{
    color: $white;
     padding-bottom: rem-calc(8);
    margin-bottom: $paragraph-margin-bottom;
    text-shadow: 3px 3px 6px rgba(0,0,5,0.3);
    position:relative;
    font-size: rem-calc(20);
    opacity:.75;
    &:after{
      background-color: $white;
      opacity:.75;
      bottom: -2px;
      content: '';
      height: 2px;
      left: 0;
      position: absolute;
      width: 68px;
    }
  }
  &__title {
    text-transform: uppercase;
    color: $white;
    font-size: rem-calc(50);
    border-bottom: 0;
    padding-bottom: 0;
    line-height: 1;
    margin-bottom: $paragraph-margin-bottom / 2;
    text-shadow: 3px 3px 6px rgba(0,0,5,0.3);
    @media #{$medium} {
      font-size: rem-calc(60);
    }
    @media #{$large} {
      font-size: rem-calc(93);
    }
  }
  &__date {
    font-size: rem-calc(16);
    color: $white;
    text-transform: uppercase;
    text-shadow: 3px 3px 6px rgba(0,0,5,0.3);
  }
  &__lead {
    @include bold;
    font-size: rem-calc(20);
    color: $white;
    line-height: 1.1;
    margin-bottom: $paragraph-margin-bottom*3;
    text-shadow: 3px 3px 6px rgba(0,0,5,0.3);
    @media #{$medium} {
      font-size: rem-calc(24);
    }
    @media #{$large} {
      font-size: rem-calc(32);
    }
  }
  &__dest-list-wrapper {
    background-color: $white;
    background-color: rgba(255,255,255,.9);
    padding: $paragraph-margin-bottom*1.5 0;
    margin-bottom: $paragraph-margin-bottom*4;
  }
  &__dest-list {
    @include undolist;
    margin: 0 !important;

    @media only screen and (min-width: em-calc(500)) {
      @include block-grid(2);
    }
    @media only screen and (min-width: em-calc(800)) {
      @include block-grid(3);
    }
    @media only screen and (min-width: em-calc(1000)) {
      @include block-grid(4);
    }

    li {
      margin-bottom: 0px !important;
    }
  }

  &__subtitle {
    @include semibold-italic;
    font-size: rem-calc(22);
    color: $black;
    margin-bottom: $paragraph-margin-bottom;
    a{
      color: $black;
      text-decoration:none;
    }
    &:before {
      @include sprite($triangles-especials);
      content: '';
      display: block;
      margin-bottom: rem-calc(10);
    }
    @media #{$medium} {
      margin-bottom: 0;
    }
  }
  &__body {
    @include poblabook;
    margin-bottom: $paragraph-margin-bottom*3;
    &--text {
      margin-bottom: $paragraph-margin-bottom*3!important;
      @media #{$large} {
        p{font-size: rem-calc(22);}
        padding-right: rem-calc(45);
      }
    }
  }
  &__intro {
    @include poblabold;
  }
}
.prepend-especial{
  .news-article__meta{
    background-color:#002947;
    padding:rem-calc(12);
    a{
      color:#fff;
    }
  }
  @media #{$small} {
    .share-icons{
      top:5px;
      right:5px;
    }
  }
}
// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$cuadrat-mes-relacionades-name: 'cuadrat-mes-relacionades';
$cuadrat-mes-relacionades-x: 178px;
$cuadrat-mes-relacionades-y: 64px;
$cuadrat-mes-relacionades-offset-x: -178px;
$cuadrat-mes-relacionades-offset-y: -64px;
$cuadrat-mes-relacionades-width: 17px;
$cuadrat-mes-relacionades-height: 17px;
$cuadrat-mes-relacionades-total-width: 197px;
$cuadrat-mes-relacionades-total-height: 180px;
$cuadrat-mes-relacionades-image: 'img/spritesheet.png?1491582020591';
$cuadrat-mes-relacionades: (178px, 64px, -178px, -64px, 17px, 17px, 197px, 180px, 'img/spritesheet.png?1491582020591', 'cuadrat-mes-relacionades', );
$especial-slide-dreta-name: 'especial-slide-dreta';
$especial-slide-dreta-x: 178px;
$especial-slide-dreta-y: 0px;
$especial-slide-dreta-offset-x: -178px;
$especial-slide-dreta-offset-y: 0px;
$especial-slide-dreta-width: 19px;
$especial-slide-dreta-height: 32px;
$especial-slide-dreta-total-width: 197px;
$especial-slide-dreta-total-height: 180px;
$especial-slide-dreta-image: 'img/spritesheet.png?1491582020591';
$especial-slide-dreta: (178px, 0px, -178px, 0px, 19px, 32px, 197px, 180px, 'img/spritesheet.png?1491582020591', 'especial-slide-dreta', );
$especial-slide-esquerra-name: 'especial-slide-esquerra';
$especial-slide-esquerra-x: 178px;
$especial-slide-esquerra-y: 32px;
$especial-slide-esquerra-offset-x: -178px;
$especial-slide-esquerra-offset-y: -32px;
$especial-slide-esquerra-width: 18px;
$especial-slide-esquerra-height: 32px;
$especial-slide-esquerra-total-width: 197px;
$especial-slide-esquerra-total-height: 180px;
$especial-slide-esquerra-image: 'img/spritesheet.png?1491582020591';
$especial-slide-esquerra: (178px, 32px, -178px, -32px, 18px, 32px, 197px, 180px, 'img/spritesheet.png?1491582020591', 'especial-slide-esquerra', );
$facebook-320-name: 'facebook-320';
$facebook-320-x: 26px;
$facebook-320-y: 129px;
$facebook-320-offset-x: -26px;
$facebook-320-offset-y: -129px;
$facebook-320-width: 26px;
$facebook-320-height: 27px;
$facebook-320-total-width: 197px;
$facebook-320-total-height: 180px;
$facebook-320-image: 'img/spritesheet.png?1491582020591';
$facebook-320: (26px, 129px, -26px, -129px, 26px, 27px, 197px, 180px, 'img/spritesheet.png?1491582020591', 'facebook-320', );
$icon-download-name: 'icon-download';
$icon-download-x: 104px;
$icon-download-y: 129px;
$icon-download-offset-x: -104px;
$icon-download-offset-y: -129px;
$icon-download-width: 30px;
$icon-download-height: 20px;
$icon-download-total-width: 197px;
$icon-download-total-height: 180px;
$icon-download-image: 'img/spritesheet.png?1491582020591';
$icon-download: (104px, 129px, -104px, -129px, 30px, 20px, 197px, 180px, 'img/spritesheet.png?1491582020591', 'icon-download', );
$icon-enllac-web-name: 'icon-enllac-web';
$icon-enllac-web-x: 178px;
$icon-enllac-web-y: 100px;
$icon-enllac-web-offset-x: -178px;
$icon-enllac-web-offset-y: -100px;
$icon-enllac-web-width: 15px;
$icon-enllac-web-height: 15px;
$icon-enllac-web-total-width: 197px;
$icon-enllac-web-total-height: 180px;
$icon-enllac-web-image: 'img/spritesheet.png?1491582020591';
$icon-enllac-web: (178px, 100px, -178px, -100px, 15px, 15px, 197px, 180px, 'img/spritesheet.png?1491582020591', 'icon-enllac-web', );
$icon-enllacos-pdf-name: 'icon-enllacos-pdf';
$icon-enllacos-pdf-x: 45px;
$icon-enllacos-pdf-y: 156px;
$icon-enllacos-pdf-offset-x: -45px;
$icon-enllacos-pdf-offset-y: -156px;
$icon-enllacos-pdf-width: 22px;
$icon-enllacos-pdf-height: 23px;
$icon-enllacos-pdf-total-width: 197px;
$icon-enllacos-pdf-total-height: 180px;
$icon-enllacos-pdf-image: 'img/spritesheet.png?1491582020591';
$icon-enllacos-pdf: (45px, 156px, -45px, -156px, 22px, 23px, 197px, 180px, 'img/spritesheet.png?1491582020591', 'icon-enllacos-pdf', );
$icon-enllacos-name: 'icon-enllacos';
$icon-enllacos-x: 178px;
$icon-enllacos-y: 130px;
$icon-enllacos-offset-x: -178px;
$icon-enllacos-offset-y: -130px;
$icon-enllacos-width: 11px;
$icon-enllacos-height: 18px;
$icon-enllacos-total-width: 197px;
$icon-enllacos-total-height: 180px;
$icon-enllacos-image: 'img/spritesheet.png?1491582020591';
$icon-enllacos: (178px, 130px, -178px, -130px, 11px, 18px, 197px, 180px, 'img/spritesheet.png?1491582020591', 'icon-enllacos', );
$icon-link-name: 'icon-link';
$icon-link-x: 23px;
$icon-link-y: 156px;
$icon-link-offset-x: -23px;
$icon-link-offset-y: -156px;
$icon-link-width: 22px;
$icon-link-height: 23px;
$icon-link-total-width: 197px;
$icon-link-total-height: 180px;
$icon-link-image: 'img/spritesheet.png?1491582020591';
$icon-link: (23px, 156px, -23px, -156px, 22px, 23px, 197px, 180px, 'img/spritesheet.png?1491582020591', 'icon-link', );
$icon-menu-small-name: 'icon-menu-small';
$icon-menu-small-x: 0px;
$icon-menu-small-y: 23px;
$icon-menu-small-offset-x: 0px;
$icon-menu-small-offset-y: -23px;
$icon-menu-small-width: 40px;
$icon-menu-small-height: 40px;
$icon-menu-small-total-width: 197px;
$icon-menu-small-total-height: 180px;
$icon-menu-small-image: 'img/spritesheet.png?1491582020591';
$icon-menu-small: (0px, 23px, 0px, -23px, 40px, 40px, 197px, 180px, 'img/spritesheet.png?1491582020591', 'icon-menu-small', );
$icon-numeros-anteriors-name: 'icon-numeros-anteriors';
$icon-numeros-anteriors-x: 178px;
$icon-numeros-anteriors-y: 115px;
$icon-numeros-anteriors-offset-x: -178px;
$icon-numeros-anteriors-offset-y: -115px;
$icon-numeros-anteriors-width: 14px;
$icon-numeros-anteriors-height: 15px;
$icon-numeros-anteriors-total-width: 197px;
$icon-numeros-anteriors-total-height: 180px;
$icon-numeros-anteriors-image: 'img/spritesheet.png?1491582020591';
$icon-numeros-anteriors: (178px, 115px, -178px, -115px, 14px, 15px, 197px, 180px, 'img/spritesheet.png?1491582020591', 'icon-numeros-anteriors', );
$icon-pdf-name: 'icon-pdf';
$icon-pdf-x: 178px;
$icon-pdf-y: 81px;
$icon-pdf-offset-x: -178px;
$icon-pdf-offset-y: -81px;
$icon-pdf-width: 14px;
$icon-pdf-height: 19px;
$icon-pdf-total-width: 197px;
$icon-pdf-total-height: 180px;
$icon-pdf-image: 'img/spritesheet.png?1491582020591';
$icon-pdf: (178px, 81px, -178px, -81px, 14px, 19px, 197px, 180px, 'img/spritesheet.png?1491582020591', 'icon-pdf', );
$linkedin-320-name: 'linkedin-320';
$linkedin-320-x: 0px;
$linkedin-320-y: 129px;
$linkedin-320-offset-x: 0px;
$linkedin-320-offset-y: -129px;
$linkedin-320-width: 26px;
$linkedin-320-height: 27px;
$linkedin-320-total-width: 197px;
$linkedin-320-total-height: 180px;
$linkedin-320-image: 'img/spritesheet.png?1491582020591';
$linkedin-320: (0px, 129px, 0px, -129px, 26px, 27px, 197px, 180px, 'img/spritesheet.png?1491582020591', 'linkedin-320', );
$logo-linkedin-name: 'logo-linkedin';
$logo-linkedin-x: 135px;
$logo-linkedin-y: 56px;
$logo-linkedin-offset-x: -135px;
$logo-linkedin-offset-y: -56px;
$logo-linkedin-width: 32px;
$logo-linkedin-height: 32px;
$logo-linkedin-total-width: 197px;
$logo-linkedin-total-height: 180px;
$logo-linkedin-image: 'img/spritesheet.png?1491582020591';
$logo-linkedin: (135px, 56px, -135px, -56px, 32px, 32px, 197px, 180px, 'img/spritesheet.png?1491582020591', 'logo-linkedin', );
$logo-twitter-name: 'logo-twitter';
$logo-twitter-x: 135px;
$logo-twitter-y: 24px;
$logo-twitter-offset-x: -135px;
$logo-twitter-offset-y: -24px;
$logo-twitter-width: 32px;
$logo-twitter-height: 32px;
$logo-twitter-total-width: 197px;
$logo-twitter-total-height: 180px;
$logo-twitter-image: 'img/spritesheet.png?1491582020591';
$logo-twitter: (135px, 24px, -135px, -24px, 32px, 32px, 197px, 180px, 'img/spritesheet.png?1491582020591', 'logo-twitter', );
$lupa-cercar-name: 'lupa-cercar';
$lupa-cercar-x: 0px;
$lupa-cercar-y: 156px;
$lupa-cercar-offset-x: 0px;
$lupa-cercar-offset-y: -156px;
$lupa-cercar-width: 23px;
$lupa-cercar-height: 24px;
$lupa-cercar-total-width: 197px;
$lupa-cercar-total-height: 180px;
$lupa-cercar-image: 'img/spritesheet.png?1491582020591';
$lupa-cercar: (0px, 156px, 0px, -156px, 23px, 24px, 197px, 180px, 'img/spritesheet.png?1491582020591', 'lupa-cercar', );
$rss-320-name: 'rss-320';
$rss-320-x: 52px;
$rss-320-y: 129px;
$rss-320-offset-x: -52px;
$rss-320-offset-y: -129px;
$rss-320-width: 26px;
$rss-320-height: 27px;
$rss-320-total-width: 197px;
$rss-320-total-height: 180px;
$rss-320-image: 'img/spritesheet.png?1491582020591';
$rss-320: (52px, 129px, -52px, -129px, 26px, 27px, 197px, 180px, 'img/spritesheet.png?1491582020591', 'rss-320', );
$share-compartir-name: 'share-compartir';
$share-compartir-x: 64px;
$share-compartir-y: 63px;
$share-compartir-offset-x: -64px;
$share-compartir-offset-y: -63px;
$share-compartir-width: 32px;
$share-compartir-height: 33px;
$share-compartir-total-width: 197px;
$share-compartir-total-height: 180px;
$share-compartir-image: 'img/spritesheet.png?1491582020591';
$share-compartir: (64px, 63px, -64px, -63px, 32px, 33px, 197px, 180px, 'img/spritesheet.png?1491582020591', 'share-compartir', );
$share-especial-facebook-name: 'share-especial-facebook';
$share-especial-facebook-x: 96px;
$share-especial-facebook-y: 96px;
$share-especial-facebook-offset-x: -96px;
$share-especial-facebook-offset-y: -96px;
$share-especial-facebook-width: 32px;
$share-especial-facebook-height: 33px;
$share-especial-facebook-total-width: 197px;
$share-especial-facebook-total-height: 180px;
$share-especial-facebook-image: 'img/spritesheet.png?1491582020591';
$share-especial-facebook: (96px, 96px, -96px, -96px, 32px, 33px, 197px, 180px, 'img/spritesheet.png?1491582020591', 'share-especial-facebook', );
$share-especial-google-plus-name: 'share-especial-google-plus';
$share-especial-google-plus-x: 103px;
$share-especial-google-plus-y: 33px;
$share-especial-google-plus-offset-x: -103px;
$share-especial-google-plus-offset-y: -33px;
$share-especial-google-plus-width: 32px;
$share-especial-google-plus-height: 33px;
$share-especial-google-plus-total-width: 197px;
$share-especial-google-plus-total-height: 180px;
$share-especial-google-plus-image: 'img/spritesheet.png?1491582020591';
$share-especial-google-plus: (103px, 33px, -103px, -33px, 32px, 33px, 197px, 180px, 'img/spritesheet.png?1491582020591', 'share-especial-google-plus', );
$share-especial-pinterest-name: 'share-especial-pinterest';
$share-especial-pinterest-x: 32px;
$share-especial-pinterest-y: 96px;
$share-especial-pinterest-offset-x: -32px;
$share-especial-pinterest-offset-y: -96px;
$share-especial-pinterest-width: 32px;
$share-especial-pinterest-height: 33px;
$share-especial-pinterest-total-width: 197px;
$share-especial-pinterest-total-height: 180px;
$share-especial-pinterest-image: 'img/spritesheet.png?1491582020591';
$share-especial-pinterest: (32px, 96px, -32px, -96px, 32px, 33px, 197px, 180px, 'img/spritesheet.png?1491582020591', 'share-especial-pinterest', );
$share-especial-share-name: 'share-especial-share';
$share-especial-share-x: 64px;
$share-especial-share-y: 96px;
$share-especial-share-offset-x: -64px;
$share-especial-share-offset-y: -96px;
$share-especial-share-width: 32px;
$share-especial-share-height: 33px;
$share-especial-share-total-width: 197px;
$share-especial-share-total-height: 180px;
$share-especial-share-image: 'img/spritesheet.png?1491582020591';
$share-especial-share: (64px, 96px, -64px, -96px, 32px, 33px, 197px, 180px, 'img/spritesheet.png?1491582020591', 'share-especial-share', );
$share-especial-twitter-name: 'share-especial-twitter';
$share-especial-twitter-x: 0px;
$share-especial-twitter-y: 96px;
$share-especial-twitter-offset-x: 0px;
$share-especial-twitter-offset-y: -96px;
$share-especial-twitter-width: 32px;
$share-especial-twitter-height: 33px;
$share-especial-twitter-total-width: 197px;
$share-especial-twitter-total-height: 180px;
$share-especial-twitter-image: 'img/spritesheet.png?1491582020591';
$share-especial-twitter: (0px, 96px, 0px, -96px, 32px, 33px, 197px, 180px, 'img/spritesheet.png?1491582020591', 'share-especial-twitter', );
$share-facebook-name: 'share-facebook';
$share-facebook-x: 71px;
$share-facebook-y: 0px;
$share-facebook-offset-x: -71px;
$share-facebook-offset-y: 0px;
$share-facebook-width: 32px;
$share-facebook-height: 33px;
$share-facebook-total-width: 197px;
$share-facebook-total-height: 180px;
$share-facebook-image: 'img/spritesheet.png?1491582020591';
$share-facebook: (71px, 0px, -71px, 0px, 32px, 33px, 197px, 180px, 'img/spritesheet.png?1491582020591', 'share-facebook', );
$share-google-plus-name: 'share-google-plus';
$share-google-plus-x: 103px;
$share-google-plus-y: 0px;
$share-google-plus-offset-x: -103px;
$share-google-plus-offset-y: 0px;
$share-google-plus-width: 32px;
$share-google-plus-height: 33px;
$share-google-plus-total-width: 197px;
$share-google-plus-total-height: 180px;
$share-google-plus-image: 'img/spritesheet.png?1491582020591';
$share-google-plus: (103px, 0px, -103px, 0px, 32px, 33px, 197px, 180px, 'img/spritesheet.png?1491582020591', 'share-google-plus', );
$share-pinterest-name: 'share-pinterest';
$share-pinterest-x: 32px;
$share-pinterest-y: 63px;
$share-pinterest-offset-x: -32px;
$share-pinterest-offset-y: -63px;
$share-pinterest-width: 32px;
$share-pinterest-height: 33px;
$share-pinterest-total-width: 197px;
$share-pinterest-total-height: 180px;
$share-pinterest-image: 'img/spritesheet.png?1491582020591';
$share-pinterest: (32px, 63px, -32px, -63px, 32px, 33px, 197px, 180px, 'img/spritesheet.png?1491582020591', 'share-pinterest', );
$share-twitter-name: 'share-twitter';
$share-twitter-x: 0px;
$share-twitter-y: 63px;
$share-twitter-offset-x: 0px;
$share-twitter-offset-y: -63px;
$share-twitter-width: 32px;
$share-twitter-height: 33px;
$share-twitter-total-width: 197px;
$share-twitter-total-height: 180px;
$share-twitter-image: 'img/spritesheet.png?1491582020591';
$share-twitter: (0px, 63px, 0px, -63px, 32px, 33px, 197px, 180px, 'img/spritesheet.png?1491582020591', 'share-twitter', );
$top-name: 'top';
$top-x: 135px;
$top-y: 0px;
$top-offset-x: -135px;
$top-offset-y: 0px;
$top-width: 43px;
$top-height: 24px;
$top-total-width: 197px;
$top-total-height: 180px;
$top-image: 'img/spritesheet.png?1491582020591';
$top: (135px, 0px, -135px, 0px, 43px, 24px, 197px, 180px, 'img/spritesheet.png?1491582020591', 'top', );
$triangles-especials-name: 'triangles-especials';
$triangles-especials-x: 0px;
$triangles-especials-y: 0px;
$triangles-especials-offset-x: 0px;
$triangles-especials-offset-y: 0px;
$triangles-especials-width: 71px;
$triangles-especials-height: 23px;
$triangles-especials-total-width: 197px;
$triangles-especials-total-height: 180px;
$triangles-especials-image: 'img/spritesheet.png?1491582020591';
$triangles-especials: (0px, 0px, 0px, 0px, 71px, 23px, 197px, 180px, 'img/spritesheet.png?1491582020591', 'triangles-especials', );
$twitter-320-name: 'twitter-320';
$twitter-320-x: 78px;
$twitter-320-y: 129px;
$twitter-320-offset-x: -78px;
$twitter-320-offset-y: -129px;
$twitter-320-width: 26px;
$twitter-320-height: 27px;
$twitter-320-total-width: 197px;
$twitter-320-total-height: 180px;
$twitter-320-image: 'img/spritesheet.png?1491582020591';
$twitter-320: (78px, 129px, -78px, -129px, 26px, 27px, 197px, 180px, 'img/spritesheet.png?1491582020591', 'twitter-320', );
$xarxes-facebook-name: 'xarxes-facebook';
$xarxes-facebook-x: 103px;
$xarxes-facebook-y: 66px;
$xarxes-facebook-offset-x: -103px;
$xarxes-facebook-offset-y: -66px;
$xarxes-facebook-width: 29px;
$xarxes-facebook-height: 30px;
$xarxes-facebook-total-width: 197px;
$xarxes-facebook-total-height: 180px;
$xarxes-facebook-image: 'img/spritesheet.png?1491582020591';
$xarxes-facebook: (103px, 66px, -103px, -66px, 29px, 30px, 197px, 180px, 'img/spritesheet.png?1491582020591', 'xarxes-facebook', );
$xarxes-linkedin-name: 'xarxes-linkedin';
$xarxes-linkedin-x: 135px;
$xarxes-linkedin-y: 88px;
$xarxes-linkedin-offset-x: -135px;
$xarxes-linkedin-offset-y: -88px;
$xarxes-linkedin-width: 29px;
$xarxes-linkedin-height: 30px;
$xarxes-linkedin-total-width: 197px;
$xarxes-linkedin-total-height: 180px;
$xarxes-linkedin-image: 'img/spritesheet.png?1491582020591';
$xarxes-linkedin: (135px, 88px, -135px, -88px, 29px, 30px, 197px, 180px, 'img/spritesheet.png?1491582020591', 'xarxes-linkedin', );
$xarxes-rss-name: 'xarxes-rss';
$xarxes-rss-x: 40px;
$xarxes-rss-y: 23px;
$xarxes-rss-offset-x: -40px;
$xarxes-rss-offset-y: -23px;
$xarxes-rss-width: 29px;
$xarxes-rss-height: 30px;
$xarxes-rss-total-width: 197px;
$xarxes-rss-total-height: 180px;
$xarxes-rss-image: 'img/spritesheet.png?1491582020591';
$xarxes-rss: (40px, 23px, -40px, -23px, 29px, 30px, 197px, 180px, 'img/spritesheet.png?1491582020591', 'xarxes-rss', );
$xarxes-twitter-name: 'xarxes-twitter';
$xarxes-twitter-x: 71px;
$xarxes-twitter-y: 33px;
$xarxes-twitter-offset-x: -71px;
$xarxes-twitter-offset-y: -33px;
$xarxes-twitter-width: 29px;
$xarxes-twitter-height: 30px;
$xarxes-twitter-total-width: 197px;
$xarxes-twitter-total-height: 180px;
$xarxes-twitter-image: 'img/spritesheet.png?1491582020591';
$xarxes-twitter: (71px, 33px, -71px, -33px, 29px, 30px, 197px, 180px, 'img/spritesheet.png?1491582020591', 'xarxes-twitter', );
$cuadrat-mes-relacionades-2x-name: 'cuadrat-mes-relacionades@2x';
$cuadrat-mes-relacionades-2x-x: 356px;
$cuadrat-mes-relacionades-2x-y: 128px;
$cuadrat-mes-relacionades-2x-offset-x: -356px;
$cuadrat-mes-relacionades-2x-offset-y: -128px;
$cuadrat-mes-relacionades-2x-width: 34px;
$cuadrat-mes-relacionades-2x-height: 34px;
$cuadrat-mes-relacionades-2x-total-width: 394px;
$cuadrat-mes-relacionades-2x-total-height: 360px;
$cuadrat-mes-relacionades-2x-image: 'img/spritesheet@2x.png?1491582020591';
$cuadrat-mes-relacionades-2x: (356px, 128px, -356px, -128px, 34px, 34px, 394px, 360px, 'img/spritesheet@2x.png?1491582020591', 'cuadrat-mes-relacionades@2x', );
$especial-slide-dreta-2x-name: 'especial-slide-dreta@2x';
$especial-slide-dreta-2x-x: 356px;
$especial-slide-dreta-2x-y: 0px;
$especial-slide-dreta-2x-offset-x: -356px;
$especial-slide-dreta-2x-offset-y: 0px;
$especial-slide-dreta-2x-width: 38px;
$especial-slide-dreta-2x-height: 64px;
$especial-slide-dreta-2x-total-width: 394px;
$especial-slide-dreta-2x-total-height: 360px;
$especial-slide-dreta-2x-image: 'img/spritesheet@2x.png?1491582020591';
$especial-slide-dreta-2x: (356px, 0px, -356px, 0px, 38px, 64px, 394px, 360px, 'img/spritesheet@2x.png?1491582020591', 'especial-slide-dreta@2x', );
$especial-slide-esquerra-2x-name: 'especial-slide-esquerra@2x';
$especial-slide-esquerra-2x-x: 356px;
$especial-slide-esquerra-2x-y: 64px;
$especial-slide-esquerra-2x-offset-x: -356px;
$especial-slide-esquerra-2x-offset-y: -64px;
$especial-slide-esquerra-2x-width: 36px;
$especial-slide-esquerra-2x-height: 64px;
$especial-slide-esquerra-2x-total-width: 394px;
$especial-slide-esquerra-2x-total-height: 360px;
$especial-slide-esquerra-2x-image: 'img/spritesheet@2x.png?1491582020591';
$especial-slide-esquerra-2x: (356px, 64px, -356px, -64px, 36px, 64px, 394px, 360px, 'img/spritesheet@2x.png?1491582020591', 'especial-slide-esquerra@2x', );
$facebook-320-2x-name: 'facebook-320@2x';
$facebook-320-2x-x: 52px;
$facebook-320-2x-y: 258px;
$facebook-320-2x-offset-x: -52px;
$facebook-320-2x-offset-y: -258px;
$facebook-320-2x-width: 52px;
$facebook-320-2x-height: 54px;
$facebook-320-2x-total-width: 394px;
$facebook-320-2x-total-height: 360px;
$facebook-320-2x-image: 'img/spritesheet@2x.png?1491582020591';
$facebook-320-2x: (52px, 258px, -52px, -258px, 52px, 54px, 394px, 360px, 'img/spritesheet@2x.png?1491582020591', 'facebook-320@2x', );
$icon-download-2x-name: 'icon-download@2x';
$icon-download-2x-x: 208px;
$icon-download-2x-y: 258px;
$icon-download-2x-offset-x: -208px;
$icon-download-2x-offset-y: -258px;
$icon-download-2x-width: 60px;
$icon-download-2x-height: 40px;
$icon-download-2x-total-width: 394px;
$icon-download-2x-total-height: 360px;
$icon-download-2x-image: 'img/spritesheet@2x.png?1491582020591';
$icon-download-2x: (208px, 258px, -208px, -258px, 60px, 40px, 394px, 360px, 'img/spritesheet@2x.png?1491582020591', 'icon-download@2x', );
$icon-enllac-web-2x-name: 'icon-enllac-web@2x';
$icon-enllac-web-2x-x: 356px;
$icon-enllac-web-2x-y: 200px;
$icon-enllac-web-2x-offset-x: -356px;
$icon-enllac-web-2x-offset-y: -200px;
$icon-enllac-web-2x-width: 30px;
$icon-enllac-web-2x-height: 30px;
$icon-enllac-web-2x-total-width: 394px;
$icon-enllac-web-2x-total-height: 360px;
$icon-enllac-web-2x-image: 'img/spritesheet@2x.png?1491582020591';
$icon-enllac-web-2x: (356px, 200px, -356px, -200px, 30px, 30px, 394px, 360px, 'img/spritesheet@2x.png?1491582020591', 'icon-enllac-web@2x', );
$icon-enllacos-pdf-2x-name: 'icon-enllacos-pdf@2x';
$icon-enllacos-pdf-2x-x: 90px;
$icon-enllacos-pdf-2x-y: 312px;
$icon-enllacos-pdf-2x-offset-x: -90px;
$icon-enllacos-pdf-2x-offset-y: -312px;
$icon-enllacos-pdf-2x-width: 44px;
$icon-enllacos-pdf-2x-height: 46px;
$icon-enllacos-pdf-2x-total-width: 394px;
$icon-enllacos-pdf-2x-total-height: 360px;
$icon-enllacos-pdf-2x-image: 'img/spritesheet@2x.png?1491582020591';
$icon-enllacos-pdf-2x: (90px, 312px, -90px, -312px, 44px, 46px, 394px, 360px, 'img/spritesheet@2x.png?1491582020591', 'icon-enllacos-pdf@2x', );
$icon-enllacos-2x-name: 'icon-enllacos@2x';
$icon-enllacos-2x-x: 356px;
$icon-enllacos-2x-y: 260px;
$icon-enllacos-2x-offset-x: -356px;
$icon-enllacos-2x-offset-y: -260px;
$icon-enllacos-2x-width: 22px;
$icon-enllacos-2x-height: 36px;
$icon-enllacos-2x-total-width: 394px;
$icon-enllacos-2x-total-height: 360px;
$icon-enllacos-2x-image: 'img/spritesheet@2x.png?1491582020591';
$icon-enllacos-2x: (356px, 260px, -356px, -260px, 22px, 36px, 394px, 360px, 'img/spritesheet@2x.png?1491582020591', 'icon-enllacos@2x', );
$icon-link-2x-name: 'icon-link@2x';
$icon-link-2x-x: 46px;
$icon-link-2x-y: 312px;
$icon-link-2x-offset-x: -46px;
$icon-link-2x-offset-y: -312px;
$icon-link-2x-width: 44px;
$icon-link-2x-height: 46px;
$icon-link-2x-total-width: 394px;
$icon-link-2x-total-height: 360px;
$icon-link-2x-image: 'img/spritesheet@2x.png?1491582020591';
$icon-link-2x: (46px, 312px, -46px, -312px, 44px, 46px, 394px, 360px, 'img/spritesheet@2x.png?1491582020591', 'icon-link@2x', );
$icon-menu-small-2x-name: 'icon-menu-small@2x';
$icon-menu-small-2x-x: 0px;
$icon-menu-small-2x-y: 46px;
$icon-menu-small-2x-offset-x: 0px;
$icon-menu-small-2x-offset-y: -46px;
$icon-menu-small-2x-width: 80px;
$icon-menu-small-2x-height: 80px;
$icon-menu-small-2x-total-width: 394px;
$icon-menu-small-2x-total-height: 360px;
$icon-menu-small-2x-image: 'img/spritesheet@2x.png?1491582020591';
$icon-menu-small-2x: (0px, 46px, 0px, -46px, 80px, 80px, 394px, 360px, 'img/spritesheet@2x.png?1491582020591', 'icon-menu-small@2x', );
$icon-numeros-anteriors-2x-name: 'icon-numeros-anteriors@2x';
$icon-numeros-anteriors-2x-x: 356px;
$icon-numeros-anteriors-2x-y: 230px;
$icon-numeros-anteriors-2x-offset-x: -356px;
$icon-numeros-anteriors-2x-offset-y: -230px;
$icon-numeros-anteriors-2x-width: 28px;
$icon-numeros-anteriors-2x-height: 30px;
$icon-numeros-anteriors-2x-total-width: 394px;
$icon-numeros-anteriors-2x-total-height: 360px;
$icon-numeros-anteriors-2x-image: 'img/spritesheet@2x.png?1491582020591';
$icon-numeros-anteriors-2x: (356px, 230px, -356px, -230px, 28px, 30px, 394px, 360px, 'img/spritesheet@2x.png?1491582020591', 'icon-numeros-anteriors@2x', );
$icon-pdf-2x-name: 'icon-pdf@2x';
$icon-pdf-2x-x: 356px;
$icon-pdf-2x-y: 162px;
$icon-pdf-2x-offset-x: -356px;
$icon-pdf-2x-offset-y: -162px;
$icon-pdf-2x-width: 28px;
$icon-pdf-2x-height: 38px;
$icon-pdf-2x-total-width: 394px;
$icon-pdf-2x-total-height: 360px;
$icon-pdf-2x-image: 'img/spritesheet@2x.png?1491582020591';
$icon-pdf-2x: (356px, 162px, -356px, -162px, 28px, 38px, 394px, 360px, 'img/spritesheet@2x.png?1491582020591', 'icon-pdf@2x', );
$linkedin-320-2x-name: 'linkedin-320@2x';
$linkedin-320-2x-x: 0px;
$linkedin-320-2x-y: 258px;
$linkedin-320-2x-offset-x: 0px;
$linkedin-320-2x-offset-y: -258px;
$linkedin-320-2x-width: 52px;
$linkedin-320-2x-height: 54px;
$linkedin-320-2x-total-width: 394px;
$linkedin-320-2x-total-height: 360px;
$linkedin-320-2x-image: 'img/spritesheet@2x.png?1491582020591';
$linkedin-320-2x: (0px, 258px, 0px, -258px, 52px, 54px, 394px, 360px, 'img/spritesheet@2x.png?1491582020591', 'linkedin-320@2x', );
$logo-linkedin-2x-name: 'logo-linkedin@2x';
$logo-linkedin-2x-x: 270px;
$logo-linkedin-2x-y: 112px;
$logo-linkedin-2x-offset-x: -270px;
$logo-linkedin-2x-offset-y: -112px;
$logo-linkedin-2x-width: 64px;
$logo-linkedin-2x-height: 64px;
$logo-linkedin-2x-total-width: 394px;
$logo-linkedin-2x-total-height: 360px;
$logo-linkedin-2x-image: 'img/spritesheet@2x.png?1491582020591';
$logo-linkedin-2x: (270px, 112px, -270px, -112px, 64px, 64px, 394px, 360px, 'img/spritesheet@2x.png?1491582020591', 'logo-linkedin@2x', );
$logo-twitter-2x-name: 'logo-twitter@2x';
$logo-twitter-2x-x: 270px;
$logo-twitter-2x-y: 48px;
$logo-twitter-2x-offset-x: -270px;
$logo-twitter-2x-offset-y: -48px;
$logo-twitter-2x-width: 64px;
$logo-twitter-2x-height: 64px;
$logo-twitter-2x-total-width: 394px;
$logo-twitter-2x-total-height: 360px;
$logo-twitter-2x-image: 'img/spritesheet@2x.png?1491582020591';
$logo-twitter-2x: (270px, 48px, -270px, -48px, 64px, 64px, 394px, 360px, 'img/spritesheet@2x.png?1491582020591', 'logo-twitter@2x', );
$lupa-cercar-2x-name: 'lupa-cercar@2x';
$lupa-cercar-2x-x: 0px;
$lupa-cercar-2x-y: 312px;
$lupa-cercar-2x-offset-x: 0px;
$lupa-cercar-2x-offset-y: -312px;
$lupa-cercar-2x-width: 46px;
$lupa-cercar-2x-height: 48px;
$lupa-cercar-2x-total-width: 394px;
$lupa-cercar-2x-total-height: 360px;
$lupa-cercar-2x-image: 'img/spritesheet@2x.png?1491582020591';
$lupa-cercar-2x: (0px, 312px, 0px, -312px, 46px, 48px, 394px, 360px, 'img/spritesheet@2x.png?1491582020591', 'lupa-cercar@2x', );
$rss-320-2x-name: 'rss-320@2x';
$rss-320-2x-x: 104px;
$rss-320-2x-y: 258px;
$rss-320-2x-offset-x: -104px;
$rss-320-2x-offset-y: -258px;
$rss-320-2x-width: 52px;
$rss-320-2x-height: 54px;
$rss-320-2x-total-width: 394px;
$rss-320-2x-total-height: 360px;
$rss-320-2x-image: 'img/spritesheet@2x.png?1491582020591';
$rss-320-2x: (104px, 258px, -104px, -258px, 52px, 54px, 394px, 360px, 'img/spritesheet@2x.png?1491582020591', 'rss-320@2x', );
$share-compartir-2x-name: 'share-compartir@2x';
$share-compartir-2x-x: 128px;
$share-compartir-2x-y: 126px;
$share-compartir-2x-offset-x: -128px;
$share-compartir-2x-offset-y: -126px;
$share-compartir-2x-width: 64px;
$share-compartir-2x-height: 66px;
$share-compartir-2x-total-width: 394px;
$share-compartir-2x-total-height: 360px;
$share-compartir-2x-image: 'img/spritesheet@2x.png?1491582020591';
$share-compartir-2x: (128px, 126px, -128px, -126px, 64px, 66px, 394px, 360px, 'img/spritesheet@2x.png?1491582020591', 'share-compartir@2x', );
$share-especial-facebook-2x-name: 'share-especial-facebook@2x';
$share-especial-facebook-2x-x: 192px;
$share-especial-facebook-2x-y: 192px;
$share-especial-facebook-2x-offset-x: -192px;
$share-especial-facebook-2x-offset-y: -192px;
$share-especial-facebook-2x-width: 64px;
$share-especial-facebook-2x-height: 66px;
$share-especial-facebook-2x-total-width: 394px;
$share-especial-facebook-2x-total-height: 360px;
$share-especial-facebook-2x-image: 'img/spritesheet@2x.png?1491582020591';
$share-especial-facebook-2x: (192px, 192px, -192px, -192px, 64px, 66px, 394px, 360px, 'img/spritesheet@2x.png?1491582020591', 'share-especial-facebook@2x', );
$share-especial-google-plus-2x-name: 'share-especial-google-plus@2x';
$share-especial-google-plus-2x-x: 206px;
$share-especial-google-plus-2x-y: 66px;
$share-especial-google-plus-2x-offset-x: -206px;
$share-especial-google-plus-2x-offset-y: -66px;
$share-especial-google-plus-2x-width: 64px;
$share-especial-google-plus-2x-height: 66px;
$share-especial-google-plus-2x-total-width: 394px;
$share-especial-google-plus-2x-total-height: 360px;
$share-especial-google-plus-2x-image: 'img/spritesheet@2x.png?1491582020591';
$share-especial-google-plus-2x: (206px, 66px, -206px, -66px, 64px, 66px, 394px, 360px, 'img/spritesheet@2x.png?1491582020591', 'share-especial-google-plus@2x', );
$share-especial-pinterest-2x-name: 'share-especial-pinterest@2x';
$share-especial-pinterest-2x-x: 64px;
$share-especial-pinterest-2x-y: 192px;
$share-especial-pinterest-2x-offset-x: -64px;
$share-especial-pinterest-2x-offset-y: -192px;
$share-especial-pinterest-2x-width: 64px;
$share-especial-pinterest-2x-height: 66px;
$share-especial-pinterest-2x-total-width: 394px;
$share-especial-pinterest-2x-total-height: 360px;
$share-especial-pinterest-2x-image: 'img/spritesheet@2x.png?1491582020591';
$share-especial-pinterest-2x: (64px, 192px, -64px, -192px, 64px, 66px, 394px, 360px, 'img/spritesheet@2x.png?1491582020591', 'share-especial-pinterest@2x', );
$share-especial-share-2x-name: 'share-especial-share@2x';
$share-especial-share-2x-x: 128px;
$share-especial-share-2x-y: 192px;
$share-especial-share-2x-offset-x: -128px;
$share-especial-share-2x-offset-y: -192px;
$share-especial-share-2x-width: 64px;
$share-especial-share-2x-height: 66px;
$share-especial-share-2x-total-width: 394px;
$share-especial-share-2x-total-height: 360px;
$share-especial-share-2x-image: 'img/spritesheet@2x.png?1491582020591';
$share-especial-share-2x: (128px, 192px, -128px, -192px, 64px, 66px, 394px, 360px, 'img/spritesheet@2x.png?1491582020591', 'share-especial-share@2x', );
$share-especial-twitter-2x-name: 'share-especial-twitter@2x';
$share-especial-twitter-2x-x: 0px;
$share-especial-twitter-2x-y: 192px;
$share-especial-twitter-2x-offset-x: 0px;
$share-especial-twitter-2x-offset-y: -192px;
$share-especial-twitter-2x-width: 64px;
$share-especial-twitter-2x-height: 66px;
$share-especial-twitter-2x-total-width: 394px;
$share-especial-twitter-2x-total-height: 360px;
$share-especial-twitter-2x-image: 'img/spritesheet@2x.png?1491582020591';
$share-especial-twitter-2x: (0px, 192px, 0px, -192px, 64px, 66px, 394px, 360px, 'img/spritesheet@2x.png?1491582020591', 'share-especial-twitter@2x', );
$share-facebook-2x-name: 'share-facebook@2x';
$share-facebook-2x-x: 142px;
$share-facebook-2x-y: 0px;
$share-facebook-2x-offset-x: -142px;
$share-facebook-2x-offset-y: 0px;
$share-facebook-2x-width: 64px;
$share-facebook-2x-height: 66px;
$share-facebook-2x-total-width: 394px;
$share-facebook-2x-total-height: 360px;
$share-facebook-2x-image: 'img/spritesheet@2x.png?1491582020591';
$share-facebook-2x: (142px, 0px, -142px, 0px, 64px, 66px, 394px, 360px, 'img/spritesheet@2x.png?1491582020591', 'share-facebook@2x', );
$share-google-plus-2x-name: 'share-google-plus@2x';
$share-google-plus-2x-x: 206px;
$share-google-plus-2x-y: 0px;
$share-google-plus-2x-offset-x: -206px;
$share-google-plus-2x-offset-y: 0px;
$share-google-plus-2x-width: 64px;
$share-google-plus-2x-height: 66px;
$share-google-plus-2x-total-width: 394px;
$share-google-plus-2x-total-height: 360px;
$share-google-plus-2x-image: 'img/spritesheet@2x.png?1491582020591';
$share-google-plus-2x: (206px, 0px, -206px, 0px, 64px, 66px, 394px, 360px, 'img/spritesheet@2x.png?1491582020591', 'share-google-plus@2x', );
$share-pinterest-2x-name: 'share-pinterest@2x';
$share-pinterest-2x-x: 64px;
$share-pinterest-2x-y: 126px;
$share-pinterest-2x-offset-x: -64px;
$share-pinterest-2x-offset-y: -126px;
$share-pinterest-2x-width: 64px;
$share-pinterest-2x-height: 66px;
$share-pinterest-2x-total-width: 394px;
$share-pinterest-2x-total-height: 360px;
$share-pinterest-2x-image: 'img/spritesheet@2x.png?1491582020591';
$share-pinterest-2x: (64px, 126px, -64px, -126px, 64px, 66px, 394px, 360px, 'img/spritesheet@2x.png?1491582020591', 'share-pinterest@2x', );
$share-twitter-2x-name: 'share-twitter@2x';
$share-twitter-2x-x: 0px;
$share-twitter-2x-y: 126px;
$share-twitter-2x-offset-x: 0px;
$share-twitter-2x-offset-y: -126px;
$share-twitter-2x-width: 64px;
$share-twitter-2x-height: 66px;
$share-twitter-2x-total-width: 394px;
$share-twitter-2x-total-height: 360px;
$share-twitter-2x-image: 'img/spritesheet@2x.png?1491582020591';
$share-twitter-2x: (0px, 126px, 0px, -126px, 64px, 66px, 394px, 360px, 'img/spritesheet@2x.png?1491582020591', 'share-twitter@2x', );
$top-2x-name: 'top@2x';
$top-2x-x: 270px;
$top-2x-y: 0px;
$top-2x-offset-x: -270px;
$top-2x-offset-y: 0px;
$top-2x-width: 86px;
$top-2x-height: 48px;
$top-2x-total-width: 394px;
$top-2x-total-height: 360px;
$top-2x-image: 'img/spritesheet@2x.png?1491582020591';
$top-2x: (270px, 0px, -270px, 0px, 86px, 48px, 394px, 360px, 'img/spritesheet@2x.png?1491582020591', 'top@2x', );
$triangles-especials-2x-name: 'triangles-especials@2x';
$triangles-especials-2x-x: 0px;
$triangles-especials-2x-y: 0px;
$triangles-especials-2x-offset-x: 0px;
$triangles-especials-2x-offset-y: 0px;
$triangles-especials-2x-width: 142px;
$triangles-especials-2x-height: 46px;
$triangles-especials-2x-total-width: 394px;
$triangles-especials-2x-total-height: 360px;
$triangles-especials-2x-image: 'img/spritesheet@2x.png?1491582020591';
$triangles-especials-2x: (0px, 0px, 0px, 0px, 142px, 46px, 394px, 360px, 'img/spritesheet@2x.png?1491582020591', 'triangles-especials@2x', );
$twitter-320-2x-name: 'twitter-320@2x';
$twitter-320-2x-x: 156px;
$twitter-320-2x-y: 258px;
$twitter-320-2x-offset-x: -156px;
$twitter-320-2x-offset-y: -258px;
$twitter-320-2x-width: 52px;
$twitter-320-2x-height: 54px;
$twitter-320-2x-total-width: 394px;
$twitter-320-2x-total-height: 360px;
$twitter-320-2x-image: 'img/spritesheet@2x.png?1491582020591';
$twitter-320-2x: (156px, 258px, -156px, -258px, 52px, 54px, 394px, 360px, 'img/spritesheet@2x.png?1491582020591', 'twitter-320@2x', );
$xarxes-facebook-2x-name: 'xarxes-facebook@2x';
$xarxes-facebook-2x-x: 206px;
$xarxes-facebook-2x-y: 132px;
$xarxes-facebook-2x-offset-x: -206px;
$xarxes-facebook-2x-offset-y: -132px;
$xarxes-facebook-2x-width: 58px;
$xarxes-facebook-2x-height: 60px;
$xarxes-facebook-2x-total-width: 394px;
$xarxes-facebook-2x-total-height: 360px;
$xarxes-facebook-2x-image: 'img/spritesheet@2x.png?1491582020591';
$xarxes-facebook-2x: (206px, 132px, -206px, -132px, 58px, 60px, 394px, 360px, 'img/spritesheet@2x.png?1491582020591', 'xarxes-facebook@2x', );
$xarxes-linkedin-2x-name: 'xarxes-linkedin@2x';
$xarxes-linkedin-2x-x: 270px;
$xarxes-linkedin-2x-y: 176px;
$xarxes-linkedin-2x-offset-x: -270px;
$xarxes-linkedin-2x-offset-y: -176px;
$xarxes-linkedin-2x-width: 58px;
$xarxes-linkedin-2x-height: 60px;
$xarxes-linkedin-2x-total-width: 394px;
$xarxes-linkedin-2x-total-height: 360px;
$xarxes-linkedin-2x-image: 'img/spritesheet@2x.png?1491582020591';
$xarxes-linkedin-2x: (270px, 176px, -270px, -176px, 58px, 60px, 394px, 360px, 'img/spritesheet@2x.png?1491582020591', 'xarxes-linkedin@2x', );
$xarxes-rss-2x-name: 'xarxes-rss@2x';
$xarxes-rss-2x-x: 80px;
$xarxes-rss-2x-y: 46px;
$xarxes-rss-2x-offset-x: -80px;
$xarxes-rss-2x-offset-y: -46px;
$xarxes-rss-2x-width: 58px;
$xarxes-rss-2x-height: 60px;
$xarxes-rss-2x-total-width: 394px;
$xarxes-rss-2x-total-height: 360px;
$xarxes-rss-2x-image: 'img/spritesheet@2x.png?1491582020591';
$xarxes-rss-2x: (80px, 46px, -80px, -46px, 58px, 60px, 394px, 360px, 'img/spritesheet@2x.png?1491582020591', 'xarxes-rss@2x', );
$xarxes-twitter-2x-name: 'xarxes-twitter@2x';
$xarxes-twitter-2x-x: 142px;
$xarxes-twitter-2x-y: 66px;
$xarxes-twitter-2x-offset-x: -142px;
$xarxes-twitter-2x-offset-y: -66px;
$xarxes-twitter-2x-width: 58px;
$xarxes-twitter-2x-height: 60px;
$xarxes-twitter-2x-total-width: 394px;
$xarxes-twitter-2x-total-height: 360px;
$xarxes-twitter-2x-image: 'img/spritesheet@2x.png?1491582020591';
$xarxes-twitter-2x: (142px, 66px, -142px, -66px, 58px, 60px, 394px, 360px, 'img/spritesheet@2x.png?1491582020591', 'xarxes-twitter@2x', );
$spritesheet-width: 197px;
$spritesheet-height: 180px;
$spritesheet-image: 'img/spritesheet.png?1491582020591';
$spritesheet-sprites: ($cuadrat-mes-relacionades, $especial-slide-dreta, $especial-slide-esquerra, $facebook-320, $icon-download, $icon-enllac-web, $icon-enllacos-pdf, $icon-enllacos, $icon-link, $icon-menu-small, $icon-numeros-anteriors, $icon-pdf, $linkedin-320, $logo-linkedin, $logo-twitter, $lupa-cercar, $rss-320, $share-compartir, $share-especial-facebook, $share-especial-google-plus, $share-especial-pinterest, $share-especial-share, $share-especial-twitter, $share-facebook, $share-google-plus, $share-pinterest, $share-twitter, $top, $triangles-especials, $twitter-320, $xarxes-facebook, $xarxes-linkedin, $xarxes-rss, $xarxes-twitter, );
$spritesheet: (197px, 180px, 'img/spritesheet.png?1491582020591', $spritesheet-sprites, );
$retina-spritesheet-width: 394px;
$retina-spritesheet-height: 360px;
$retina-spritesheet-image: 'img/spritesheet@2x.png?1491582020591';
$retina-spritesheet-sprites: ($cuadrat-mes-relacionades-2x, $especial-slide-dreta-2x, $especial-slide-esquerra-2x, $facebook-320-2x, $icon-download-2x, $icon-enllac-web-2x, $icon-enllacos-pdf-2x, $icon-enllacos-2x, $icon-link-2x, $icon-menu-small-2x, $icon-numeros-anteriors-2x, $icon-pdf-2x, $linkedin-320-2x, $logo-linkedin-2x, $logo-twitter-2x, $lupa-cercar-2x, $rss-320-2x, $share-compartir-2x, $share-especial-facebook-2x, $share-especial-google-plus-2x, $share-especial-pinterest-2x, $share-especial-share-2x, $share-especial-twitter-2x, $share-facebook-2x, $share-google-plus-2x, $share-pinterest-2x, $share-twitter-2x, $top-2x, $triangles-especials-2x, $twitter-320-2x, $xarxes-facebook-2x, $xarxes-linkedin-2x, $xarxes-rss-2x, $xarxes-twitter-2x, );
$retina-spritesheet: (394px, 360px, 'img/spritesheet@2x.png?1491582020591', $retina-spritesheet-sprites, );

// These "retina group" variables are mappings for the naming and pairing of normal and retina sprites.
//
// The list formatted variables are intended for mixins like `retina-sprite` and `retina-sprites`.
$cuadrat-mes-relacionades-group-name: 'cuadrat-mes-relacionades';
$cuadrat-mes-relacionades-group: ('cuadrat-mes-relacionades', $cuadrat-mes-relacionades, $cuadrat-mes-relacionades-2x, );
$especial-slide-dreta-group-name: 'especial-slide-dreta';
$especial-slide-dreta-group: ('especial-slide-dreta', $especial-slide-dreta, $especial-slide-dreta-2x, );
$especial-slide-esquerra-group-name: 'especial-slide-esquerra';
$especial-slide-esquerra-group: ('especial-slide-esquerra', $especial-slide-esquerra, $especial-slide-esquerra-2x, );
$facebook-320-group-name: 'facebook-320';
$facebook-320-group: ('facebook-320', $facebook-320, $facebook-320-2x, );
$icon-download-group-name: 'icon-download';
$icon-download-group: ('icon-download', $icon-download, $icon-download-2x, );
$icon-enllac-web-group-name: 'icon-enllac-web';
$icon-enllac-web-group: ('icon-enllac-web', $icon-enllac-web, $icon-enllac-web-2x, );
$icon-enllacos-pdf-group-name: 'icon-enllacos-pdf';
$icon-enllacos-pdf-group: ('icon-enllacos-pdf', $icon-enllacos-pdf, $icon-enllacos-pdf-2x, );
$icon-enllacos-group-name: 'icon-enllacos';
$icon-enllacos-group: ('icon-enllacos', $icon-enllacos, $icon-enllacos-2x, );
$icon-link-group-name: 'icon-link';
$icon-link-group: ('icon-link', $icon-link, $icon-link-2x, );
$icon-menu-small-group-name: 'icon-menu-small';
$icon-menu-small-group: ('icon-menu-small', $icon-menu-small, $icon-menu-small-2x, );
$icon-numeros-anteriors-group-name: 'icon-numeros-anteriors';
$icon-numeros-anteriors-group: ('icon-numeros-anteriors', $icon-numeros-anteriors, $icon-numeros-anteriors-2x, );
$icon-pdf-group-name: 'icon-pdf';
$icon-pdf-group: ('icon-pdf', $icon-pdf, $icon-pdf-2x, );
$linkedin-320-group-name: 'linkedin-320';
$linkedin-320-group: ('linkedin-320', $linkedin-320, $linkedin-320-2x, );
$logo-linkedin-group-name: 'logo-linkedin';
$logo-linkedin-group: ('logo-linkedin', $logo-linkedin, $logo-linkedin-2x, );
$logo-twitter-group-name: 'logo-twitter';
$logo-twitter-group: ('logo-twitter', $logo-twitter, $logo-twitter-2x, );
$lupa-cercar-group-name: 'lupa-cercar';
$lupa-cercar-group: ('lupa-cercar', $lupa-cercar, $lupa-cercar-2x, );
$rss-320-group-name: 'rss-320';
$rss-320-group: ('rss-320', $rss-320, $rss-320-2x, );
$share-compartir-group-name: 'share-compartir';
$share-compartir-group: ('share-compartir', $share-compartir, $share-compartir-2x, );
$share-especial-facebook-group-name: 'share-especial-facebook';
$share-especial-facebook-group: ('share-especial-facebook', $share-especial-facebook, $share-especial-facebook-2x, );
$share-especial-google-plus-group-name: 'share-especial-google-plus';
$share-especial-google-plus-group: ('share-especial-google-plus', $share-especial-google-plus, $share-especial-google-plus-2x, );
$share-especial-pinterest-group-name: 'share-especial-pinterest';
$share-especial-pinterest-group: ('share-especial-pinterest', $share-especial-pinterest, $share-especial-pinterest-2x, );
$share-especial-share-group-name: 'share-especial-share';
$share-especial-share-group: ('share-especial-share', $share-especial-share, $share-especial-share-2x, );
$share-especial-twitter-group-name: 'share-especial-twitter';
$share-especial-twitter-group: ('share-especial-twitter', $share-especial-twitter, $share-especial-twitter-2x, );
$share-facebook-group-name: 'share-facebook';
$share-facebook-group: ('share-facebook', $share-facebook, $share-facebook-2x, );
$share-google-plus-group-name: 'share-google-plus';
$share-google-plus-group: ('share-google-plus', $share-google-plus, $share-google-plus-2x, );
$share-pinterest-group-name: 'share-pinterest';
$share-pinterest-group: ('share-pinterest', $share-pinterest, $share-pinterest-2x, );
$share-twitter-group-name: 'share-twitter';
$share-twitter-group: ('share-twitter', $share-twitter, $share-twitter-2x, );
$top-group-name: 'top';
$top-group: ('top', $top, $top-2x, );
$triangles-especials-group-name: 'triangles-especials';
$triangles-especials-group: ('triangles-especials', $triangles-especials, $triangles-especials-2x, );
$twitter-320-group-name: 'twitter-320';
$twitter-320-group: ('twitter-320', $twitter-320, $twitter-320-2x, );
$xarxes-facebook-group-name: 'xarxes-facebook';
$xarxes-facebook-group: ('xarxes-facebook', $xarxes-facebook, $xarxes-facebook-2x, );
$xarxes-linkedin-group-name: 'xarxes-linkedin';
$xarxes-linkedin-group: ('xarxes-linkedin', $xarxes-linkedin, $xarxes-linkedin-2x, );
$xarxes-rss-group-name: 'xarxes-rss';
$xarxes-rss-group: ('xarxes-rss', $xarxes-rss, $xarxes-rss-2x, );
$xarxes-twitter-group-name: 'xarxes-twitter';
$xarxes-twitter-group: ('xarxes-twitter', $xarxes-twitter, $xarxes-twitter-2x, );
$retina-groups: ($cuadrat-mes-relacionades-group, $especial-slide-dreta-group, $especial-slide-esquerra-group, $facebook-320-group, $icon-download-group, $icon-enllac-web-group, $icon-enllacos-pdf-group, $icon-enllacos-group, $icon-link-group, $icon-menu-small-group, $icon-numeros-anteriors-group, $icon-pdf-group, $linkedin-320-group, $logo-linkedin-group, $logo-twitter-group, $lupa-cercar-group, $rss-320-group, $share-compartir-group, $share-especial-facebook-group, $share-especial-google-plus-group, $share-especial-pinterest-group, $share-especial-share-group, $share-especial-twitter-group, $share-facebook-group, $share-google-plus-group, $share-pinterest-group, $share-twitter-group, $top-group, $triangles-especials-group, $twitter-320-group, $xarxes-facebook-group, $xarxes-linkedin-group, $xarxes-rss-group, $xarxes-twitter-group, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `retina-sprite` mixin sets up rules and a media query for a sprite/retina sprite.
//   It should be used with a "retina group" variable.
//
// The media query is from CSS Tricks: https://css-tricks.com/snippets/css/retina-display-media-query/
//
// $icon-home-group: ('icon-home', $icon-home, $icon-home-2x, );
//
// .icon-home {
//   @include retina-sprite($icon-home-group);
// }
@mixin sprite-background-size($sprite) {
  $sprite-total-width: nth($sprite, 7);
  $sprite-total-height: nth($sprite, 8);
  background-size: $sprite-total-width $sprite-total-height;
}

@mixin retina-sprite($retina-group) {
  $normal-sprite: nth($retina-group, 2);
  $retina-sprite: nth($retina-group, 3);
  @include sprite($normal-sprite);

  @media (-webkit-min-device-pixel-ratio: 2),
         (min-resolution: 192dpi) {
    @include sprite-image($retina-sprite);
    @include sprite-background-size($normal-sprite);
  }
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}

// The `retina-sprites` mixin generates a CSS rule and media query for retina groups
//   This yields the same output as CSS retina template but can be overridden in SCSS
//
// @include retina-sprites($retina-groups);
@mixin retina-sprites($retina-groups) {
  @each $retina-group in $retina-groups {
    $sprite-name: nth($retina-group, 1);
    .#{$sprite-name} {
      @include retina-sprite($retina-group);
    }
  }
}

.box{
  &__hero{
    background:#f0f0f0;
    @media #{$medium-up} {
      //padding-top:rem-calc(25);
    }

    .wrap{
      //box-shadow: 0 5px 10px rgba(0,0,0,.15);

      padding:rem-calc(0);
      input[type=text],input[type=email]{
        border:none;
        border-bottom: 1px solid #acacac;
        background-color:transparent;
        box-shadow: none;
        margin-top: rem-calc(12);
        margin-bottom: rem-calc(35);
        font-size: rem-calc(20);
        padding:0;
        height: auto;
        &:focus, &.not-empty{
          border-color: $primary-color;
            box-shadow: 0 3px 0 0 $primary-color;
        }
      }
      textarea{
        height: 150px;
      }
      label:not(.checkbox-label){
          display: block;
          left: rem-calc(15);
          position: absolute;
          top: rem-calc(14);
          transition: .2s ease-out;

      }
      label.error{
        top:rem-calc(47);
        font-size: rem-calc(11);
        background: #9F202D;
        color: #fff;
        padding: 3px;
      }
      label.textarea{
        position: relative;
        left:0;
        margin-bottom: 1em;
      }
      p.type-label{
        margin-bottom: rem-calc(4);
      }
      label.radio-label{
        font-size: rem-calc(22);
        display: inline-block;
        margin-right: rem-calc(20);
        padding-bottom:rem-calc(35);
        &.open{
          padding-bottom: 0;
        }
      }
      input[type=text]:focus + label:not(.checkbox-label), input[type=text].not-empty + label:not(.checkbox-label), input[type=email]:focus + label:not(.checkbox-label), input[type=email].not-empty + label:not(.checkbox-label){
        color: $primary-color;
        font-size: rem-calc(12);
        font-style: normal;
        font-weight: 700;
        letter-spacing: .26em;
        text-transform: uppercase;
        -ms-transform: translateY(-120%);
        transform: translateY(-120%);
      }
      .num-compte label{
        position: relative;
        left:0;
        &.error{
          position: absolute;
          left: 8px;
        }
      }
    }
    &--header{
      //background:$primary-color;
      padding:rem-calc(15);
      margin-bottom:rem-calc(30);
      position: relative;
      h1{
        color:#3e3e3e;
        border:none;
        text-align: center;
        margin:0;
        //text-shadow: 0px 2px 3px rgba(0,0,0,0.4);
      }
      h2{
        color:#3e3e3e;
        border:none;
        text-align: center;
        font-size: rem-calc(20);
      }
    }
    &--content{
      padding:rem-calc(0 20 20);
      .button{
        font-size: rem-calc(20);
        background-color: darken($primary-color, 20%);
          color: #fff;
          border-color: darken($primary-color, 20%);
           margin:rem-calc(35) auto;
           display: block;
      }

      .intro{
        margin-bottom: rem-calc(35);
        p{
          color:$secondary-color;
          border:none;
          text-align: center;
          font-size: rem-calc(20);
          strong,b{
            font-size: rem-calc(24);
          }
        }
      }
    }
    &--footer{
      p{
        font-size: rem-calc(12);
      }
      .message{
        padding:rem-calc(35);
        text-align: center;
        border-top:solid 1px #aaa;
        margin-top:rem-calc(35);
        p{
          font-size: rem-calc(18);
        }
      }
    }
    &--thanks{
      background-color: #ceeaff;
      text-align: center;
      .main-content{min-height: 315px}
      h2{
        padding-top: rem-calc(60);
        font-style:italic;
        font-size:rem-calc(45);
        color:#000;
      }
      h3{
        background-color:#389AAC;
        color:#fff;
        padding: rem-calc(20);
        margin-bottom: rem-calc(60);
      }
    }
    &.contacte{
      .box__hero--header{
        h2{
          text-align: left;
        }
      }
    }

  }

}

.tribuna {
  margin-bottom: $paragraph-margin-bottom*2;

  &__wrapper {
    background-color: $ghost;
    padding: $paragraph-margin-bottom;
    @media only screen and (min-width: em-calc(600)) {
      padding-top: $paragraph-margin-bottom * 2;
      padding-bottom: $paragraph-margin-bottom * 2;
    }
  }

  .column {
    @media only screen and (min-width: em-calc(600)) {
      @include grid-column($columns:4);
    }
    /*@media only screen and (min-width: em-calc(801)) and (max-width: em-calc(1024)) {
      @include grid-column($columns:6);
    }*/
  }

  h2 {
    text-align: center;
    margin-bottom: $paragraph-margin-bottom;
    @media only screen and (min-width: em-calc(600)) {
      margin-bottom: $paragraph-margin-bottom * 2;
    }
  }

  &__item {
    border-top: 3px solid $white;
    padding-top: $paragraph-margin-bottom;
    padding-bottom: $paragraph-margin-bottom;
    @media only screen and (min-width: em-calc(500)) {
      text-align: center;
    }
    @media only screen and (min-width: em-calc(600)) {
      border-top: 0;
      padding-top: 0;
      padding-bottom: 0;
    }
    @media only screen and (min-width: em-calc(801)) and (max-width: em-calc(1024)) {
      padding-bottom: $paragraph-margin-bottom;
    }
  }

  &__img-container {
    @include grid-column($columns: 5);

    @media only screen and (min-width: em-calc(500)) {
      @include grid-column($columns: 12);
    }
  }

  &__txt-container {
    @include grid-column($columns: 7);
    padding-left: 0;

    @media only screen and (max-width: em-calc(500)) {
      padding-top: 3px;
    }

    @media only screen and (min-width: em-calc(500)) {
      @include grid-column($columns: 12);
    }
  }

  &__img {
    border-radius: $global-rounded;
    @media only screen and (min-width: em-calc(500)) {
      margin-bottom: $paragraph-margin-bottom;
    }
  }

  &__title {
    font-size: rem-calc(20);
    margin-bottom: $paragraph-margin-bottom / 2;
    @media #{$medium} {
      font-size: rem-calc(22);
    }
    a {
      @include hover;
      color: $steel;
    }
  }

  &__autor {
    @include regular-italic;
    font-size: rem-calc(14);
    margin-bottom: 0;
    color: $black;
    @media #{$medium} {
      font-size: rem-calc(16);
    }
  }

  &__tema {
    text-transform: uppercase;
    font-size: rem-calc(14);
    margin-bottom: 0;
    color: $black;
    @media #{$medium} {
      font-size: rem-calc(16);
    }
  }
}


// apartat opinió

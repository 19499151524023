.explorar{
  background-color: #002947;

  padding-bottom: rem-calc(40);
  &.b{
    padding:rem-calc(40 0 80);
    .container{
        margin-bottom: $paragraph-margin-bottom*2;
        box-shadow: 0 5px 10px rgba(0,0,0,.15);
    }
  }
  &.c{
    margin-top: rem-calc(-30);
  }
  h2{
    color:$white;
  }
  .container{
    a {
        &:hover,
        &:focus {
            .container__info {
                top: 0;
                background-color: $primary-color;
            }
            .container__lead {
                opacity: 1;
                top:32%;
            }
        }
    }
    overflow: hidden;
    position:relative;
    &__info {
        position: absolute;
        left: $column-gutter/2;
        right: $column-gutter/2;
        bottom: 0;
        background-color: $primary-color;
        background-color: rgba($primary-color, .7);
        padding: $column-gutter/4 $column-gutter/2;
        //transition: background-color .1s ease-in;
        @include single-transition();
        @media #{$large-up} {
            top: 50%;
            @include single-transition();
            padding-top: $column-gutter/2;
            padding-bottom: $column-gutter/2;

        }
        &.b{
            left:0;
            right: 0;
        }
        @media #{$xlarge-up} {
            &.b{
                top: 70%;
            }
        }
    }
    &__lead {
        opacity: 0;
        @include single-transition(.6s);
        margin-top:rem-calc(25);
        color: $white;
        font-size:rem-calc(15);
        position:absolute;
        bottom:-155%;
        padding-right:rem-calc(10);
         @media #{$medium-up} {
            font-size:rem-calc(17);
        }
        @media #{$xlarge-up} {
            font-size:rem-calc(22);
        }
    }
    &__date {
        color: $white;
        font-size: rem-calc(14);
        display: block;
        @media #{$medium-up} {
            font-size: rem-calc(16);
        }
    }
    &__title {
        color: $white;
        font-size: rem-calc(20);
        margin-bottom: 0;
        @media #{$medium-up} {
            font-size: rem-calc(22);
        }
        @media #{$large-up} {
            font-size: rem-calc(24);
        }
    }
  }
  .boto-mes{
    margin:0;
    a{
        border: 1px solid $white;
        color:$primary-color;
        background-color: $white;
        &:hover{
            color:$white;
            border-color:$primary-color;
            background-color:$primary-color;
        }
    }
  }
}
.section{

    h2.section__title{
        color:$primary-color;
        font-size: rem-calc(25);
        border-top:solid 3px $primary-color;
        padding-top:rem-calc(25);
        line-height: 1;
        a{
            text-decoration: none;
        }
    }
    .boto-mes{
        text-align: left;
        margin-bottom:0;
        a{
           font-size: rem-calc(12);
            min-width: 50px;
            padding: 0 0.5rem;
        }
        &:last-child{
            margin-top:rem-calc(20);
             margin-bottom:rem-calc(20);
        }
    }
}
.top-banner {
  background-color: #fff;
  padding: rem-calc(16 0);
  text-align: center;
  display: block;
  @media only screen and (min-width: em-calc(660)) {
    display: block;
  }
}
.main-header {
  background-color: $primary-color !important;
  padding: rem-calc(16 0 0);
  text-align: center;
    margin-bottom: 2rem;
   height: 74px;
  @media only screen and (min-width: em-calc(660)) {
    padding: rem-calc(28 0 0);
    height: auto;
  }
}
.logo {
  margin: 0 auto rem-calc(20);
  padding: 0;
  border: 0;
  font-size: 1em;
  @media only screen and (min-width: em-calc(660)) {
  	float: left;
    margin: 0 auto rem-calc(17);
  }
  img {
  	width: 175px;
  	@media only screen and (min-width: em-calc(660)) {
  		width: 227px;
  	}
    @media #{$medium} {
      width: 247px;
    }
  }
}

.tools {
  display: none;
  p{
    margin-bottom:rem-calc(20);
    text-align:right;
    margin-right:rem-calc(10);
    margin-top:-8px;
    color:$white;
    font-size:rem-calc(14);
  }
	@media only screen and (min-width: em-calc(660)) {
		float: right;
    display: block;
	}
}

.trad {
  display: inline-block;
  font-size: rem-calc(14);
  background-color: $white;
  padding: rem-calc(3 10);
  border-radius: rem-calc(100);
  text-decoration: none;
  vertical-align: top;
  transition: background-color .1s ease-in;

  &:after {
    @include triangle(5px, $primary-color, top);
    content: '';
    display: inline-block;
    margin-left: 5px;
    vertical-align: middle;
  }

  &:hover {
    background-color: rgba(255,255,255,.8);
  }

  @media only screen and (min-width: em-calc(660)) {
    font-size: rem-calc(16);
    padding: rem-calc(3 10);
    margin-top: rem-calc(4.5);
  }
  @media #{$medium} {
    margin-top: rem-calc(8.5);
  }
}
.tools__item{
  display: inline-block;
  margin: rem-calc(0 10);
  position:relative;
  a.link{
    color: $white;
    text-decoration: none;
  }
  .dropdown__toggle{
    color: $white;
    position: relative;
    text-decoration: none;
    &.is-active:before {
        content: '';
        display: inline-block;
        width: 0;
        height: 0;
        border-top: 0;
        position: absolute;
        left: 50%;
        border-left: rem-calc(6) solid transparent;
        border-right: rem-calc(6) solid transparent;
        border-bottom: rem-calc(6) solid $white;
        top: rem-calc(33);
        z-index: 3;
    }
    &:after {
        content: '';
        display: inline-block;
        pointer-events: none;
        width: 0;
        height: 0;
        border-bottom: 0;
        margin-top: rem-calc(2);
        margin-bottom: rem-calc(2);
        margin-left: rem-calc(4);
        border-left: rem-calc(4) solid transparent;
        border-right: rem-calc(4) solid transparent;
        border-top: rem-calc(4) solid $white;
    }
    &.is-active:after{
    border-top: 0;
    border-bottom: rem-calc(4) solid $white;
    }
  }
  .dropdown{
    box-sizing: border-box;
    position: absolute;
    z-index: 2;
    margin: 0;
    list-style: none;
    min-width: rem-calc(250);
    background: $white;
    -webkit-box-shadow: 0 0 4px rgba(0,0,0,.3);
    box-shadow: 0 0 4px rgba(0,0,0,.3);
    left: auto;
    right: 0;
    top: rem-calc(38);
    padding: rem-calc(16);
    text-align: left;
    ul{
      li{
        border-bottom:solid 1px $silver;
        padding-bottom:rem-calc(8);
        margin-bottom:rem-calc(8);
        font-size: rem-calc(18);
        &:last-child{
          border:none;
          margin-bottom: 0;
          padding-bottom:0;
        }
        a{
          padding: rem-calc(8);
          &:hover{
            color:#000;
          }
        }
      }
    }
  }
  &--hemeroteca{
    color:#000;
    .dropdown{
      left: -50%;
      min-width: rem-calc(450);
      margin-left: -100px;

      a{
        text-decoration: none;
      }
      img{
          float: left;
          padding-right: rem-calc(25);
          background: #fff;
          max-width:160px;
        }
      .box__info{
        min-height: 185px;
        border-bottom:solid 1px $silver;
        display: block;
      }
      span{
          border-radius: 100px;
          border: 1px solid $steel;
          padding: .5rem 1rem;
          text-align: center;
          color:$steel;
          transition: all .3s ease-out;
          display: inline-block;
          &:hover{
              background-color:$steel;
              color:$white;
          }
        }
      .altres{
        position:absolute;
        bottom: rem-calc(16);
        right:rem-calc(16);
        color:$jumbo;
        &:hover{
          color:$black;
        }
      }
    }
  }
  &--search{
    margin-right: 0;
    .dropdown{
      min-width: rem-calc(450);
        &:before {
          content: '';
          display: inline-block;
          width: 0;
          height: 0;
          border-top: 0;
          position: absolute;
          right: rem-calc(16);
          border-left: rem-calc(6) solid transparent;
          border-right: rem-calc(6) solid transparent;
          border-bottom: rem-calc(6) solid $white;
          top: -5px;
          z-index: 3;
      }
      input{
        border:none;
        border-bottom:solid 1px $steel;
        width: 100%;
        outline: 0;
        padding:rem-calc(8);
        font-size: rem-calc(22);
        box-shadow:none;
        height: auto;
        &:active,&:focus,&:hover{
          border-color:#03A9F4;
          background-color: #fff;
        }
      }
      button{
        position:absolute;
        top:rem-calc(27);
        right: rem-calc(15);
        padding:rem-calc(4 8);
        margin:0;
        background:#03A9F4;
        @include single-transition();
        &:hover{
          background-color:darken(#03A9F4,15%);
        }
      }
    }
  }
}
.is-off{
  display: none;
}
.is-active{
  display: block;
}
.search {
  text-decoration: none;
  font-size: rem-calc(14);
  color: $white;
  display: inline-block;
  margin: rem-calc(0 12 15);
  vertical-align: top;
  transition: opacity .1s ease-in;

  &:before {
    @include sprite($lupa-cercar);
    content: '';
    display: block;
    margin: 0 auto;
  }
  &:after{
    content: none;
  }

  &:hover {
    color: $white;
    opacity: .8;
  }

  @media only screen and (min-width: em-calc(660)) {
    font-size: rem-calc(16);
     margin: rem-calc(0 10);
    vertical-align: middle;
  }
}

.box {
  background-color: $ghost;
  padding: $paragraph-margin-bottom;
  margin-bottom: $column-gutter;
  border-bottom: 1px solid $base;

  &__title {
    text-transform: uppercase;
    font-size: rem-calc(17);
    color: $steel;
    border-bottom: 3px solid $white;
    padding-bottom: $paragraph-margin-bottom*.75;
    margin-bottom: $paragraph-margin-bottom;
  }

  &__info {
    padding-left: $paragraph-margin-bottom / 2;
  }

  &__subtitle {
    @include semibold;
    font-size: rem-calc(17);
    color: $steel;
    margin-bottom: 0;
    &--bigger {
      font-size: rem-calc(50);
      line-height: 1;
      margin-bottom: rem-calc(10);
    }
  }

  &__number {
    font-size: rem-calc(17);
    color: $steel;
    margin-bottom: $paragraph-margin-bottom;
  }

  &__actions {
    @include undolist;
    font-size: rem-calc(15);
    margin-bottom: 0;
      a {
        @include hover;
        display: block;
        color: $steel;
        padding-left: 20px;
        position: relative;

        &:before {
          display: block;
          content: '';
          margin-right: 6px;
          vertical-align: middle;
          position: absolute;
          left: 0;
          top: 50%;
        }
      }
    &--dl {
      &:before {
        @include sprite($icon-pdf);
        margin-top: -$icon-pdf-height / 2;
      }
    }
    &--prev {
      &:before {
        @include sprite($icon-numeros-anteriors);
        margin-top: -$icon-numeros-anteriors-height / 2;
      }
    }
    &--web {
      &:before {
        @include sprite($icon-enllac-web);
        margin-top: -$icon-enllac-web-height / 2;
      }
    }
  }

  &__text {
    color: $steel;
    font-size: rem-calc(17);
    margin-bottom: 0;
  }

  &__list {
    @include undolist;
    margin: 0;
    li {
      margin-bottom: $paragraph-margin-bottom*1.5;
      &:last-child {
        margin-bottom: 0;
      }
    }
    h3 {
      font-size: rem-calc(19);
      margin-bottom: rem-calc(5);
      a {
        @include hover;
        color: $steel;
      }
    }
    p {
      text-transform: uppercase;
      font-size: rem-calc(14);
      margin-bottom: 0;
      a {
        @include hover;
      }
    }
  }

  // caixa blava
  &--options {
    background-color: $primary-color;
    border-bottom: 0;

    &__logo {
      font-size: 1rem;
      width: rem-calc(147);
      margin: 0 auto $paragraph-margin-bottom;
    }

    &__list {
      @include undolist;
      font-size: rem-calc(17);
      margin-bottom: 0;
      li:last-child {
        a {
          padding-bottom: 0;
        }
      }
      a {
        @include hover;
        color: $white;
        display: block;
        padding: .8em 0;
        border-top: 2px solid rgba(255,255,255,.25);
      }
    }
  }
  .en-paper{
    display: block;
    position:relative;
    .box__info{
      position:absolute;
      top:0;
      left:0;
      width: 100%;
      height: 100%;
      background: rgba($white,.75);
      opacity:0;
      @include single-transition();
      .box__modal{
        text-align: center;
        position:absolute;
        left:50%;
        top:50%;
        transform: translate(-50%,-50%);
       background: #fff;
        padding: 2rem;
        .box__subtitle,.box__number{
          margin-bottom: rem-calc(12);
        }
        span{
          display: block;
          background-color: $primary-color;
          padding: .1875rem .625rem;
          border-radius: 6.25rem;
          color:#fff;
          vertical-align: top;
          margin-top: rem-calc(20);

        }
      }
    }
    &:hover{
      .box__info{
        opacity:1;
      }
    }
  }
}

// layout per .box
@media only screen and (min-width: em-calc(500)) and (max-width: em-calc(800)) {
  .boxes-wrapper {
    @include grid-column($columns:6)
  }
}
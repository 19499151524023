.share-icons {
	@include undolist;
	font-size: 0;
	margin: $paragraph-margin-bottom / 2 0 0;
	.especial__bg & {
		position: static;
		float: right;
		margin-top: -14px;
	}
	@media only screen and (min-width: em-calc(500)) {
		position: absolute;
		right: 0;
		top: -13px;
		margin: 0;

		.especial__bg & {
			position: static;
			float: right;
			margin-top: -14px;
		}
	}

	li {
		display: inline;
	}

	a {
		display: inline-block;
		margin-right: 16px;
		transition: opacity .1s ease-in;

		&:hover {
			opacity: .8;
		}

		@media only screen and (min-width: em-calc(500)) {
			margin-right: 0;
			margin-left: 16px;
		}
	}
	.twitter {
		@include sprite($share-twitter);
	}
	.facebook {
		@include sprite($share-facebook);
	}
	.google {
		@include sprite($share-google-plus);
	}

	// icons blancs per apartat Especial
	&--especial {
		.twitter {
			@include sprite($share-especial-twitter);
		}
		.facebook {
			@include sprite($share-especial-facebook);
		}
		.google {
			@include sprite($share-especial-google-plus);
		}
	}
}
.social {
	@include undolist;
	font-size: 0;

	.main-header & {
		margin: 0;
		display: inline-block;
		vertical-align: top;

		@media only screen and (min-width: em-calc(660)) {
			margin-bottom: 0;
			margin-top: rem-calc(4.5);
		}
		@media #{$medium} {
			margin-top: rem-calc(8.5);
		}
	}

	li {
		display: inline;

		&:first-child a {
			margin-left: 0;
		}
	}

	a {
		display: inline-block;
		margin: 0 0 0 6px;
		transition: opacity .1s ease-in;

		&:hover {
			opacity: .8;
		}

		@media #{$medium} {
			margin-left: 7px;
		}
	}

	.facebook {
		@include sprite($facebook-320);
		@media only screen and (min-width: em-calc(660)) {
			@include sprite($xarxes-facebook);
		}
	}
	.twitter {
		@include sprite($twitter-320);
		@media only screen and (min-width: em-calc(660)) {
			@include sprite($xarxes-twitter);
		}
	}
	.linkedin {
		@include sprite($linkedin-320);
		@media only screen and (min-width: em-calc(660)) {
			@include sprite($xarxes-linkedin);
		}
	}
	.rss {
		@include sprite($rss-320);
		@media only screen and (min-width: em-calc(660)) {
			@include sprite($xarxes-rss);
		}
	}

}